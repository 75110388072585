<template>
  <div>
    <h1>{{ breadcrumb() }}</h1>
    <plant-protection-mixture-list @loaded="checkSurface" ref="list" @rowclick="model = $event" :route=true
                                   :routeParams="routeParams" :av="true" :params="gridParams">
      <a href="#" @click.prevent="model = {}" class="button" v-if="hasRole('ROLE_MIXTURE_MANAGER')">
        dodaj
        <i class="ico ico-add"/>
      </a>
      <a href="#" @click.prevent="add = true" class="button" v-if="hasRole('ROLE_MIXTURE_MANAGER')">
        powiel
        <i class="ico ico-add"/>
      </a>
      <archive-button v-model="routeParams.archive" @input="refresh()"/>
    </plant-protection-mixture-list>
    <plant-protection-mixture-list v-if="add" @rowclick="makeCopy($event)" @exit="add = false" :modal=true :route=false
                                   :params="searchParams">
      <template v-slot:header>
        <h2>Powiel mieszaninę</h2>
      </template>
      <template v-slot:footer>
        <a href="#" @click.stop="add = false" class="button">anuluj <i class="ico ico-close"></i></a>
      </template>
    </plant-protection-mixture-list>
    <model-form v-if="model" :model="model" :lastSurface="lastSurface" @saved="lastSurface = $event.surface"
                @created="lastSurface = $event.surface" @exit="model = false" @refresh="refresh()"
                @duplicate="duplicate($event)"/>
  </div>
</template>
<script>
import ModelForm from './form/PlantProtectionMixture'
import moment from 'moment'
import PlantProtectionMixtureList from './list/PlantProtectionMixture'
import { mapActions } from "vuex";

export default {
  components: { ModelForm, PlantProtectionMixtureList },
  data() {
    return {
      model: false,
      add: false,
      lastSurface: null,
      gridParams: {
        own: true
      },
      searchParams: {
        import: true,
        archive: true
      },
      routeParams: {
        archive: false
      }
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    checkSurface() {
      if (this.lastSurface === null && this.$refs.list.grid.collection.length > 0) {
        this.lastSurface = this.$refs.list.grid.collection[0].surface
      }
    },
    breadcrumb() {
      return 'Moje mieszaniny';
    },
    refresh() {
      this.model = false
      this.$refs.list.load()
    },
    duplicate(position) {
      this.model = false
      this.add = false
      this.$nextTick(() => this.makeCopy(position))
    },
    makeCopy(position) {
      let cp = JSON.parse(JSON.stringify(position));
      cp.id = null
      cp.plantProtections.forEach((p) => {
        p.id = null
      })
      cp.fertilizers.forEach((p) => {
        p.id = null
      })
      cp.creationDate = moment().format()
      this.add = false;
      this.model = cp;
    }
  }
}
</script>
