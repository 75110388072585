<template>
    <nav class="tabmenu">
        <router-link :to="{path : '/user/change_password'}">{{$t('change_password.title')}}</router-link>
    </nav>
</template>
 
<script>
export default {
    name : 'usermenu'
}
</script>
 
