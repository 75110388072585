<template>
  <div>
    <h1>{{ breadcrumb() }}</h1>
    <div class="edit-table">
      <div class="btn-bar">
        <div class="left">
          <a
              v-if="hasRole('ROLE_MACHINE_MANAGER')"
              href="#"
              @click.prevent="create = true"
              class="button">
            dodaj nowy
            <i class="ico ico-add"></i>
          </a>
        </div>
        <div class="right">
          <search v-model="search" @filter="filter()" :print="true"/>
        </div>
      </div>
      <div class="line"></div>
      <div v-if="create || model" class="new">
        <model-form
            @refresh="load()"
            :model="model"
            @exit="create = false; model = null"
            @created="create = false"
            @saved="model = null"
        />
        <div class="line"></div>
      </div>
      <div class="grid">
        <div class="head">
          <div class="name">Nazwa</div>
          <div class="width">Szerokość [m]</div>
          <div class="task">Zadanie</div>
          <div class="model">Marka - Model</div>
          <div class="year">Rocznik</div>
          <div class="description">Opis</div>
          <div class="av">Udostępnienie</div>
        </div>
        <template v-if="grid.collection.length > 0">
          <template v-for="(position, i) in grid.collection">
            <div class="row" @click.prevent="model = position" :key="i">
              <div class="name">
                <text-highlight :queries="highlight">{{ position.name }}</text-highlight>
              </div>
              <div class="num width">
                {{ $n(position.width, 'simple') }}
              </div>
              <div class="task">
                <text-highlight v-for="task in position.taskDefinition" :key="task.id" :queries="highlight">
                  {{ task.name }}
                </text-highlight>
              </div>
              <div class="model">
                <text-highlight :queries="highlight">{{ position.model }}</text-highlight>
              </div>
              <div class="year num">
                {{ position.productionYear }}
              </div>
              <div class="description">
                <description :queries="highlight" :value="position.description"/>
              </div>
              <div class="av center btn-cell">
                <enabled :value="position.enabled"/>
                <a href="#"><i class="icon-edit"></i></a>
              </div>
            </div>
          </template>
        </template>
        <div class="row" v-else>
          <div class="empty" colspan="13">
            <span v-if="$route.query.search">
              Nic nie znaleziono.
              <button type="button" class="button" @click.prevent="clearFilter()">
                usuń filtr
              </button>
            </span>
            <span v-else>Lista jest pusta</span>
          </div>
        </div>
      </div>
      <paginator :pages="grid.pagination.pageCount" :active="grid.pagination.page" @click="onPage"/>
    </div>
  </div>
</template>

<script>
import machinesApi from '@/api/machines'
import ModelForm from '@/components/agro/form/Machine'
import TextHighlight from 'vue-text-highlight'
import Paginator from '@/components/util/Paginator'
import Search from '@/components/util/Search'
import { mapActions } from "vuex";

export default {
  components: { ModelForm, TextHighlight, Paginator, Search },
  created() {
    this.load();
  },
  data() {
    return {
      create: false,
      search: this.$route.query.search,
      model: null,
      grid: {
        collection: [],
        pagination: {
          itemsLength: 0,
          page: 1,
          pageCount: 1,
        }
      }
    }
  },
  computed: {
    highlight() {
      if (typeof this.search != 'string') {
        return '';
      }
      return this.search;
    }
  },
  watch: {
    $route() {
      this.search = this.$route.query.search;
      this.load();
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    breadcrumb() {
      return 'Maszyny';
    },
    clearFilter() {
      this.search = null;
      this.filter();
    },
    onPage(page) {
      this.updateQuery({
        page: page
      })
    },
    filter() {
      this.updateQuery({
        search: this.search,
        page: 1
      })
    },
    updateQuery(params) {
      let query = Object.assign({}, this.$route.query, params);
      if (query.page === 1) {
        delete query.page;
      }
      if (!query.search) {
        delete query.search;
      }
      this.$router.push({ query: query }).catch(() => {
        this.load();
      });
    },
    load() {
      machinesApi.getMachines({
        search: this.$route.query.search,
        page: this.$route.query.page || 1
      }).then((resp) => {
        resp.data.collection.forEach(function (item) {
          item.edit = false;
        })
        this.grid = resp.data;

      });
    },
    saved(i, old, item) {
      old.edit = false;
      this.grid.collection[i] = { ...item };
      this.grid.collection[i].edit = false;
    }
  },
}
</script>

<style scoped>
div.name {
  width: 120px;
}

div.av {
  width: 90px;
}

div.task {
  width: 120px;
}

div.width {
  width: 120px;
}

div.model {
  width: 150px;
}

div.year {
  width: 60px;
}
</style>
