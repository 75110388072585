<template>
  <modal :class="modal ? 'modal-grid' : 'content-grid'" :enabled="modal">
    <template v-slot:header>
      <slot name="header"></slot>
      <div class="btn-bar">
        <div class="left">
          <slot></slot>
        </div>
        <div class="right">
          <search v-model="query.search" @filter="filter()" ref="search" :print="!modal"/>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid">
        <div class="head">
          <div class="crop">Roślina</div>
          <div class="name">Nazwa</div>
          <div class="norm">Norma</div>
          <div class="quantity">Stan</div>
          <div class="description">Opis</div>
          <div class="av" v-if="av">Udostępnienie</div>
        </div>
        <div class="scrollable" v-if="grid.collection.length > 0">
          <template v-for="(position, i) in grid.collection">
            <div :class="'row' + (isExcluded(position) ? ' disabled' : '')" @click.prevent="rowClick(position)"
                 v-bind:key="i">
              <div class="crop">
                <span v-if="position.crop"><text-highlight :queries="highlight">{{
                    position.crop.name
                  }}</text-highlight></span>
                <span v-else>-</span>
              </div>
              <div class="name">
                <text-highlight :queries="highlight">{{ position.name }}</text-highlight>
              </div>
              <div class="norm num">
                {{ $n(position.norm, 'simple') }}
              </div>
              <div class="quantity num">
                {{ $n(position.stock, 'simple') }} kg
              </div>
              <div class="description">
                <description :queries="highlight" :value="position.description"/>
              </div>
              <div class="av center btn-cell" v-if="av">
                <enabled :value="position.enabled"/>
                <a href="#"><i class="icon-edit"></i></a>
              </div>
            </div>
          </template>
        </div>
        <div class="row" v-else>
          <div class="empty">
                        <span v-if="grid.params.search">
                            Nic nie znaleziono. <button type="button" class="button" @click.prevent="clearFilter()">usuń filtr</button>
                            <span v-if="grid.sub && grid.sub.total > 0">
                                <br/>Pozycja jest Moich roślinach <span v-if="!grid.sub.data[0].enabled">jako niedostępna</span>: <em>{{
                                grid.sub.data[0].name
                              }}</em>
                            </span>
                        </span>
            <span v-else>Lista jest pusta. <strong>Sprawdź niedostępne.</strong></span>
          </div>
        </div>

      </div>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left">
          <paginator :pages="grid.pagination.pageCount" :active="grid.pagination.page" @click="onPage"/>
        </div>
        <div class="right">
          <slot name="footer"/>
        </div>
      </div>

    </template>
  </modal>
</template>

<script>
import TextHighlight from 'vue-text-highlight'
import Paginator from '@/components/util/Paginator'
import Search from '@/components/util/Search'
import Unit from '@/components/widget/Unit'
import labelsApi from '@/api/labels'

export default {
  components: { TextHighlight, Paginator, Search, Unit },
  name: 'crop-list',
  props: {
    crop: {
      type: Object,
      default: null
    },
    av: {
      type: Boolean,
      default: true
    },
    route: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: function () {
        return {}
      }
    },
    routeParams: {
      type: Object,
      default: function () {
        return {}
      }
    },
    exclude: null,
    search: null
  },
  data() {
    return {
      create: false,
      query: this.route ? {
        search: this.$route.query.search
      } : {
        search: this.search
      },
      grid: {
        collection: [],
        pagination: {
          page: 1,
          pageCount: 1,
          itemsLength: 1
        },
        params: {}
      },
      extras: {
        crop: this.crop ? this.crop.id : null
      }
    }
  },
  watch: {
    $route() {
      if (this.$route) {
        this.query.search = this.$route.query.search
        this.query.page = this.$route.query.page
        this.load()
      }
    },
    params() {
      this.load();
    },
    routeParams() {
      if (this.route) {
        this.$router.push({ query: { ...this.query, ...this.routeParams } }).catch(() => {
          this.load()
        });
      } else {
        this.load()
      }
    },
    crop() {
      this.extras.crop = this.crop ? this.crop.id : null
    }
  },
  computed: {
    highlight() {
      if (typeof this.query.search != 'string') {
        return ''
      }
      return this.query.search
    }
  },
  mounted() {
    this.load()
    this.$bus.$emit('focus', this.$refs.search)
  },
  methods: {
    clearFilter() {
      this.query.search = null
      this.filter()
    },
    onPage(page) {
      this.updateQuery({
        page: page
      })
    },
    filter() {
      this.updateQuery({
        search: this.query.search,
        page: 1
      })
    },
    updateQuery(params) {
      let query = { ...this.query, ...params };
      if (query.page === 1) {
        delete query.page
      }
      if (!query.search) {
        delete query.search
      }

      if (this.route) {
        this.$router.push({ query: query }).catch(() => {
          this.load()
        });
      } else {
        this.query = { search: null, page: 1, ...query };
        this.load()
      }

    },
    load() {
      labelsApi.getLabels({
        search: this.query.search,
        page: this.query.page || 1
      }).then((resp) => {
        resp.data.collection.forEach(function (item) {
          item.edit = false
        })
        this.grid = resp.data
      });
    },
    isExcluded(row) {
      if (!this.exclude) {
        return false;
      }
      if (this.exclude === 'own' && row.stockId > 0) {
        return true;
      }

      return Array.isArray(this.exclude) && this.exclude.includes(row.id);
    },
    rowClick(row) {
      if (!this.isExcluded(row)) {
        this.$emit('rowclick', row)
      }
    }
  },
}
</script>

<style scoped lang="scss">
div.name {
  width: 250px;
}

div.crop {
  width: 250px;
}

div.norm {
  width: 80px;
}

div.quantity {
  width: 100px;
}

div.av {
  width: 90px;
}
</style>
