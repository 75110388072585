<template>
  <span :class="error ? 'has-error' : ''">
    <slot>
    </slot>
    <span class="error">
        {{ error }}
    </span>
  </span>
</template>
<script>
import moment from 'moment';
export default {
  props: ['error'],
  mounted () {
    
  },
  methods: {
    
  }
}
</script>