import axiosApi from '@/api/axios';

export default {
    getFields(params) {
        return axiosApi.get('/fields', {
        params,
        });
    },
    createField(params) {
        return axiosApi.post('/fields', params);
    },
    updateField(id, params) {
        return axiosApi.put(`/fields/${id}`, params);
    },
    deleteField(id) {
        return axiosApi.delete(`/fields/${id}`);
    },
}
