import UserLogin from './components/Login'
import SSO from './components/SSO'
import Activate from './components/Activate'
import Help from './components/pages/Help'
import NotFound from './components/pages/NotFound'
import ChangePassword from './components/user/ChangePassword'
import Dashboard from './components/Dashboard'
import Crops from './components/agro/Crops';
import Employers from './components/agro/Employers';
import Fertilizers from './components/agro/Fertilizers';
import Fields from './components/agro/Fields';
import Labels from './components/agro/Labels';
import Machines from './components/agro/Machines';
import Vehicles from './components/agro/Vehicles';
import Tasks from './components/agro/Tasks';
import PlantProtections from './components/agro/PlantProtections';
import PlantProtectionsMixtures from './components/agro/PlantProtectionsMixtures';
import TaskDefinition from './components/agro/TaskDefinition';
import Season from './components/agro/Season';

import Vue from 'vue';
import VueRouter from "vue-router";
import store from './store';

Vue.use(VueRouter)

const routes = [
    {
        path : '/login',
        component: UserLogin,
        name :'login',
        meta: {
            public: true,
            baseClass : 'base base-bgs'
        }
    },
    {
        path : '/sso/:token',
        component: SSO,
        name :'sso',
        props: true,
        meta: {
            public: true,
            baseClass : 'base base-bgs'
        }
    },
    {
        path : '/activate/:id/:token',
        component: Activate,
        name :'activate',
        props: true,
        meta: {
            auth: false,
            baseClass : 'base base-bgs'
        }
    },
    {
        path : '/',
        component: Dashboard,
        name : 'home',
        meta : {
            auth: true
        }
    },
    {
        path : '/tasks',
        component: Tasks,
        name : 'orders',
        meta : {
            auth: 'ROLE_TASK'
        }
    },
    {
        path : '/fields',
        component: Fields,
        name : 'fields',
        meta : {
            auth: 'ROLE_FIELD'
        }
    },
    {
        path : '/crops',
        component: Crops,
        name : 'crops',
        meta : {
            auth: 'ROLE_CROP'
        }
    },
    {
        path : '/plant-protection',
        name : 'plantProtections',
        component : PlantProtections,
        meta : {
            auth: 'ROLE_PLANT_PROTECTION'
        }
    },
    {
        path : '/plant-protection-mixtures',
        name : 'plantProtectionsMixtures',
        component : PlantProtectionsMixtures,
        meta : {
            auth: 'ROLE_MIXTURE'
        }
    },
    {
        path : '/fertilizers',
        name : 'fertilizers',
        component : Fertilizers,
        meta : {
            auth: 'ROLE_FERTILIZER'
        }
    },
    {
        path : '/labels',
        component : Labels,
        name : 'labels',
        meta : {
            auth: 'ROLE_LABEL'
        }
    },
    {
        path : '/task-definitions',
        name : 'taskDefinitions',
        component : TaskDefinition,
        meta : {
            auth: 'ROLE_TASK_DEFINITION'
        }
    },
    {
        path : '/seasons',
        name : 'seasons',
        component : Season,
        meta : {
            auth: 'ROLE_PLANT_SEASON'
        }
    },
    {
        path : '/machines',
        name : 'machines',
        component : Machines,
        meta : {
            auth: 'ROLE_MACHINE'
        }
    },
    {
        path : '/vehicles',
        name : 'vehicles',
        component : Vehicles,
        meta : {
            auth: 'ROLE_VEHICLE'
        }
    },
    {
        path : '/employers',
        name : 'employers',
        component : Employers,
        meta : {
            auth: 'ROLE_EMPLOYER'
        }
    },
    {
        path : '/help',
        component : Help,
        meta: {
            auth: true
        }
    },
    {
        path :'/user',
        name : 'user',
        redirect : {
            path : '/user/change_password'
        },
        meta : {
            auth: true
        }
    },
    {
        path : '/user/change_password',
        component : ChangePassword,
        meta: {
            auth: true
        }
    },
    {
        path: '*',
        component : NotFound
    }
]

Vue.router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    // base: window.base_url,
    routes
})

Vue.router.beforeEach((to, from, next) => {
    const isUserLoggedIn = store.getters['auth/isLoggedIn'];
    const isPublic = to.matched.some((record) => record.meta.public);

    if (isPublic || isUserLoggedIn) {
        next();
    } else {
        next({ name: 'login' });
    }
});

export default Vue.router
