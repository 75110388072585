import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import filtersApi from '@/api/filters';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
  },
  state: {
    filters: {
      taskDefinitions: [],
      machines: [],
      fields: [],
      vehicles: [],
    }
  },
  mutations: {
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
  },
  actions: {
    getFilters({ commit }) {
      // API call to get filters
      filtersApi.getFilters()
        .then((response) => {
          commit('SET_FILTERS', response.data);
        });
    }
  },
});
