<template>
    <input type="number"  lang="pl" class="text numberfield" :step=".01" :value="parsed" v-on:blur="blur" v-on:input="parsed != parse($event.target.value) && $emit('input', $event.target.value)"  ref="input" />
</template>
<script> 
    import Units from '@/service/unit.js'
    export default {
        name :'field-unit',
        props : ['value', 'unit'],
        data() {
            return {
                step : Units.getStep(this.unit),
                formatted : this.format()
            }
        },
        watch : {
            unit() {
                this.step = Units.getStep(this.unit)
                this.formatted = this.format()
            },
            value() {
                if (document.activeElement != this.$el) {
                    this.formatted = this.format()
                }
            }
        },
        methods:{
            focus() {
                this.$el.focus()
            },
            blur() {
                this.formatted = this.format()
            },
            format() {
                let v = this.value
                if(v) {
                    if (typeof v == 'string') {
                        v = parseFloat(this.value)
                    }
                    v = this.$n(v, 'simple')
                }
                return v
            },
            parse(value) {
                if (value == '' || value == null) {
                    return null
                }
                if (typeof value == 'string') {
                    return parseFloat(value)
                }
                
                return value;
            }
        },
        computed: {
            parsed() {
                if (this.value == '' || this.value == null) {
                    return null
                }
                if (typeof this.value == 'string') {
                    return parseFloat(this.value)
                }
                
                return this.parse(this.value);
            }
        }
    }
</script>
