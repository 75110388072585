import axiosApi from '@/api/axios';
export default {
    getTasks(params) {
        return axiosApi.get('/task_definitions', {
            params,
        });
    },
    createTask(params) {
        return axiosApi.post('/task_definitions', params);
    },
    updateTask(id, params) {
        return axiosApi.put(`/task_definitions/${id}`, params);
    },
    deleteTask(id) {
        return axiosApi.delete(`/task_definitions/${id}`);
    },
}
