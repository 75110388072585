import axiosApi from "@/api/axios";

export default {
    getVehicles(params) {
        return axiosApi.get("/vehicles", {
        params,
        });
    },
    createVehicle(params) {
        return axiosApi.post("/vehicles", params);
    },
    updateVehicle(id, params) {
        return axiosApi.put(`/vehicles/${id}`, params);
    },
    deleteVehicle(id) {
        return axiosApi.delete(`/vehicles/${id}`);
    },
}
