import axiosApi from "@/api/axios";

export default {
    getMachines(params) {
        return axiosApi.get("/machines", {
        params,
        });
    },
    createMachine(params) {
        return axiosApi.post("/machines", params);
    },
    updateMachine(id, params) {
        return axiosApi.put(`/machines/${id}`, params);
    },
    deleteMachine(id) {
        return axiosApi.delete(`/machines/${id}`);
    },
}
