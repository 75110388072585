import axiosApi from "@/api/axios";

export default {
    getLabels(params) {
        return axiosApi.get("/labels", {
        params,
        });
    },
    createLabel(params) {
        return axiosApi.post("/labels", params);
    },
    updateLabel(id, params) {
        return axiosApi.put(`/labels/${id}`, params);
    },
    deleteLabel(id) {
        return axiosApi.delete(`/labels/${id}`);
    },
}
