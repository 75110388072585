<template>
   <div class="pagination">
     <div class="right"><slot></slot></div>
     <template v-if="pages > 1">
        <a v-show="active > 1" @click.prevent="click(1)" class="i first"></a>
        <a v-show="active > 1" @click.prevent="click(active - 1)" class="i prev"></a>
        <a v-for="n in numbers()" :class="n == active ? 'active' : ''" @click.prevent="click(n)">{{ n }}</a>
        <a v-show="active < pages" @click.prevent="click(active + 1)" class="i next"></a>
        <a v-show="active < pages" @click.prevent="click(pages)" class="i last"></a>
     </template>
   </div>
</template>

<script>
   export default {
   name :'paginator',
    props: {
        pages : {
        },
        active : {
        }
    },
    methods:{
        click(n) {
            this.$emit('click', n);
        },
        numbers() {
            let res = [],max = 5;
            let from =  Math.max(1, this.active - max), to = Math.min(this.pages, from + max)
            for (let i = from; i <= to; i++) {
                res.push(i);
            }

            return res;
        }
    }

   }
</script>

<style scoped>
.pagination {
    padding: 10px 0px;
    position: relative;
    text-align: left;
    line-height: 18px;
  }
  .pagination a {
    display: inline-block;
    padding: 2px 7px;
    margin-right: 3px;
    height: 18px;
    color: #0065a2;
    font-size: 14px;
    font-weight: 300;
  }
  .pagination a.active {
    color: white;
    background: #0065a2;
  }
  .pagination .i {
    position: relative;
    top: -2px;
  }
  .pagination .next {
    background: url(../../../images/pagination-next.png) no-repeat center center;
    vertical-align: middle;
  }
  .pagination .last {
    background: url(../../../images/pagination-last.png) no-repeat center center;
    vertical-align: middle;
  }

  .pagination .first {
    background: url(../../../images/pagination-first.png) no-repeat center center;
    vertical-align: middle;
  }
  .pagination .prev {
    background: url(../../../images/pagination-prev.png) no-repeat center center;
    vertical-align: middle;
  }
  .pagination .right {right: 0px;; height: 100%; overflow: hidden; position: absolute;}
</style>
