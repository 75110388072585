// any CSS you require will output into a single css file (app.css in this case)
import '../css/ubuntu/fonts.css';
import '../css/reset.css';
import 'animate.css/animate.min.css'
import 'vue2-animate/dist/vue2-animate.min.css'
import '../css/app.scss';

import "@babel/polyfill";
//import "@babel/helpers";

import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/pl'
import '@formatjs/intl-listformat/polyfill'
import '@formatjs/intl-listformat/locale-data/pl'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/pl'
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/pl'
import '@formatjs/intl-datetimeformat/polyfill'
import '@formatjs/intl-datetimeformat/locale-data/pl'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/pl'

import VTooltip from 'v-tooltip'
import VueForm from 'vue-form/dist/vue-form.min.js';
import Modal from './components/util/Modal.vue'
import Alert from './components/util/Alert.vue'
import Enabled from './components/util/Enabled.vue'
import Pre from './components/util/Pre.vue'
import ArchiveButton from './components/util/ArchiveButton.vue'
import Confirm from './components/util/Confirm.vue'
import Notifications from 'vue-notification'
import TextareaAutosize from 'vue-textarea-autosize'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/pl';
import vSelect from 'vue-select'

import Vue from 'vue';

import router from './routes.js'
import store from './store';
import VueI18n from 'vue-i18n'
import * as Sentry from "@sentry/vue";

import errorService from "./service/error_service";
import { EventBus } from './service/bus.js';
import { Dictionary } from './service/dictionary.js'

import App from '@/components/App.vue'

Vue.component('datepicker', DatePicker)
Vue.use(VTooltip)
Vue.use(VueForm, {
    inputClasses: {
        valid: 'form-control-success',
        invalid: 'form-control-danger'
    },
    validators : {
        matches: function (value, attrValue) {
            if(!attrValue) {
              return true;
             }
            return value === attrValue;
        },
        'password-strength': function (value) {
              return /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[a-zA-Z]).*$/.test(value);
        }
    },
    showMessages : '$submitted'
})
Vue.use(Notifications)
Vue.use(VueI18n)

const numberFormats = {
    'pl': {
        currency: {
          style: 'currency', currency: 'PLN', currencyDisplay :'symbol'
        },
        stat : {
            style: 'decimal',
            minimumFractionDigits : 2,
            maximumFractionDigits : 2
        },
        one : {
            style: 'decimal',
            minimumFractionDigits : 1,
            maximumFractionDigits : 1
        },
        simple : {
            style: 'decimal',
            minimumFractionDigits : 0
        }
    }
};
let locale = 'pl'
if (typeof(Storage) !== "undefined") {
    locale = window.localStorage.getItem('lang') || locale
}
const i18n = new VueI18n({
    locale: locale,
    fallbackLocale : 'en',
    messages: {
        pl : require('./i18n/pl.json'),
        en : require('./i18n/en.json'),
    },
    numberFormats: numberFormats
})

Vue.prototype.$bus = EventBus;
Vue.prototype.$dictionary = Dictionary
 Vue.prototype.$errors = errorService;
 Vue.prototype.$currency = function(price, currency) {
     if (currency) {
         return this.$n(price, {
             'key' : 'currency',
             'currency' : currency,
             'currencyDisplay' : 'symbol'
         })
     } else {
         return this.$n(price[0], {
             'key' : 'currency',
             'currency' : price[1] || 'PLN',
             'currencyDisplay' : 'symbol'
         })
     }
 }
 Vue.prototype.$userSetting = function(name, def) {
     if (typeof name != 'object') {
         return this.$auth.user().settings[name] || def;
     } else {
         let changes = {}, dirty = false;

         Object.keys(name).forEach((key) => {
             if (this.$auth.user().settings[key] != name[key]) {
                 dirty = true;
                 changes[key] = name[key];
             }
         })
         if (dirty) {
             this.$http.put('user/settings', changes).then((resp) => {
                 Object.keys(changes).forEach((key) => {
                     this.$auth.user().settings[key] = changes[key];
                 })
             });
         }
     }
 }

 Vue.component('v-select', vSelect)
 Vue.component('modal',Modal)
 Vue.component('alert',Alert)
 Vue.component('confirm',Confirm)
 Vue.component('enabled',Enabled)
 Vue.component('description', Pre)
 Vue.component('archive-button', ArchiveButton)
 Vue.use(TextareaAutosize)

Vue.config.productionTip = false

Sentry.init({
    Vue,
    dsn: "https://d52100fda35c33517d7d9800ec163206@sentry.kczpl.xyz/3",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/app\.agrosmart\.dev/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

export default new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App),
})
