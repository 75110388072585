<template>
  <modal class="modal-form" @exit="$emit('exit')">
    <template v-slot:header>
      <h2 v-if="position.id">Edycja maszyny <strong>{{ position.name }}</strong></h2>
      <h2 v-else>Nowa maszyna <strong>{{ position.name }}</strong></h2>
    </template>
    <template v-slot:body>
      <form @submit.stop.prevent="save" class="form-table">
        <div class="field-row">
          <div class="form-group name">
            <label>Nazwa</label>
            <div class="form-text">
              <input-wrapper :error="errors.name">
                <input type="text" class="text" v-model="position.name" :disabled="!editEnabled" ref="first"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group norm">
            <label>AgroID</label>
            <div class="form-text">
              <input-wrapper :error="errors.agroId">
                <input type="text" class="text" v-model="position.agroId" :disabled="!editEnabled"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group quantity-group">
            <label>Marka - Model</label>
            <div class="form-text">
              <input-wrapper :error="errors.model">
                <input type="text" class="text" v-model="position.model" :disabled="!editEnabled"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group norm">
            <label>Rocznik</label>
            <div class="form-text">
              <input-wrapper :error="errors.productionYear">
                <input
                    v-model="position.productionYear"
                    type="number"
                    lang="pl"
                    step="1"
                    min="1500"
                    max="3000"
                    class="text"
                    :disabled="!editEnabled"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group norm">
            <label>Badanie techniczne</label>
            <div class="form-text">
              <input-wrapper :error="errors.technicalReview">
                <datepicker v-model="position.technicalReview" valueType="YYYY-MM-DD" :clearable="editEnabled"
                            format="DD.MM.YYYY" :disabled="!editEnabled"></datepicker>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group norm">
            <label>Przegląd okresowy</label>
            <div class="form-text">
              <input-wrapper :error="errors.serviceReview">
                <datepicker v-model="position.serviceReview" valueType="YYYY-MM-DD" :clearable="editEnabled"
                            format="DD.MM.YYYY" :disabled="!editEnabled"></datepicker>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group norm">
            <label>Nr rejestracyjny</label>
            <div class="form-text">
              <input-wrapper :error="errors.registrationNumber">
                <input type="text" class="text" v-model="position.registrationNumber" :disabled="!editEnabled"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group groups">
            <label>Opis</label>
            <div class="form-text">
              <input-wrapper :error="errors.description">
                <textarea-autosize v-model="position.description" :disabled="!editEnabled"></textarea-autosize>
              </input-wrapper>
            </div>
          </div>

        </div>
      </form>
      <confirm v-if="remove" @close="remove=false" @ok="onRemove">
        Zostanie usunięta maszyna <strong>{{ position.name }}</strong>
      </confirm>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left form-table">
          <div class="field-row">
            <div class="form-group simple-group">
              <label>Dostępność</label>
              <div class="form-control">
                <label><input type="checkbox" :disabled="!editEnabled" name="check" id="position-enabled"
                              v-model="position.enabled"/> włącz</label>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <button v-if="editEnabled" type="submit" class="button" @click="save">zapisz <i class="ico ico-save"></i>
          </button>
          <button type="button" @click.prevent="$emit('exit')" class="button">anuluj</button>
          <button type="button" v-if="editEnabled && model" @click.prevent="remove = true" class="button button-danger">
            usuń <i class="ico ico-remove"></i></button>
        </div>
      </div>
    </template>
  </modal>

</template>

<script>
import InputWrapper from '../../util/Input';
import Confirm from '../../util/Confirm';
import Modal from '../../util/Modal';
import tasksApi from "@/api/tasks";
import vehiclesApi from "@/api/vehicles";
import { mapActions } from "vuex";

export default {
  props: { model: null },
  components: { InputWrapper, Confirm, Modal },
  mounted() {
    this.$bus.$emit('focus', this.$refs.first);
    this.load();
  },
  data() {
    const tmp = this.model || {
      name: '',
      description: '',
      productionYear: null,
      model: null,
      technicalReview: null,
      serviceReview: null,
      registrationNumber: null,
      width: null,
      enabled: true,
    };
    return {
      position: { ...tmp },
      errors: {},
      remove: false,
      norm: false,
      taskDefinitions: [],
      editEnabled: !!this.hasRole('ROLE_VEHICLE_MANAGER')
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    load() {
      tasksApi.getTasks().then((resp) => {
        this.taskDefinitions = resp.data.collection;
      });

    },
    onRemove() {
      vehiclesApi.deleteVehicle(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh');
        this.$emit('exit');
      });

    },
    save() {
      if (!this.editEnabled) {
        return
      }
      let promise = null;
      if (this.model && this.model.id) {
        promise = vehiclesApi.updateVehicle(this.model.id, this.position);
      } else {
        promise = vehiclesApi.createVehicle(this.position);
      }

      promise.then(() => {
        this.$emit('refresh');
        this.errors = {};
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        if (this.model) {
          this.$emit('saved', this.position);
        } else {
          this.$emit('created', this.position);
        }
      }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors = this.$errors.transform(err.response.data)
          this.$notify({
            title: this.$t('error.form'),
            type: 'error'
          })

        } else {
          this.$notify({
            title: this.$t('error.connection'),
            type: 'error'
          })
        }
      })
    }
  },
  watch: {}
}
</script>

<style scoped lang="scss">
input[type=text], input[type=number], select {
  width: 100%;
}

form {
  color: black;
}

.check {
  white-space: nowrap;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

.line {
  margin-bottom: 10px;
}

.row {
  padding-bottom: 10px;

  .form-addon {
    width: 60px;
    font-size: 14px;
  }

  .form-input {
    padding-right: 10px;
    flex: 1;
  }
}
</style>
