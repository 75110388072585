import axiosApi from "@/api/axios";

export default {
    getEmployees(params) {
        return axiosApi.get("/employees", {
            params,
        });
    },
    createEmployee(params) {
        return axiosApi.post("/employees", params);
    },
    updateEmployee(id, params) {
        return axiosApi.put(`/employees/${id}`, params);
    },
    deleteEmployee(id) {
        return axiosApi.delete(`/employees/${id}`);
    },
}
