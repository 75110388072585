<template>
  <div class="login">
    <form method="POST" :class="formClass" action="#" v-on:submit.prevent="loginSubmit" v-if="remind === false">
      <h3><i></i> {{ $t('login.title') }}</h3>
      <transition name="bounce">
        <span class="error" v-if="error">{{ error }}</span>
      </transition>
      <input type="text" ref="login" v-model="username" :placeholder="$t('login.username')" class="txt name"/>
      <input type="password" v-model="password" ref="password" :placeholder="$t('login.password')" class="txt pass"/>

      <div class="btns">
        <a href="#" @click.prevent="remind = true; error = false" v-if="false">{{ $t('login.remind') }}</a>
        <input type="submit" class="button" :value="$t('login.submit')"/>
      </div>
    </form>
    <form method="POST" :class="formClass" action="#" v-on:submit.prevent="remindPass" v-if="remind">
      <h3><i></i> {{ $t('remind.title') }}</h3>
      <transition name="bounce">
        <span class="error" v-if="error">{{ error }}</span>
      </transition>
      <input type="text" ref="username" v-model="username" :placeholder="$t('login.username')" class="txt name"/>
      <input type="text" ref="email" v-model="email" :placeholder="$t('login.mail')" class="txt name"/>
      <div class="btns">
        <a href="#" @click.prevent="remind = false; error = false">{{ $t('remind.login') }}</a>
        <input type="submit" class="button" :value="$t('remind.submit')"/>
      </div>
    </form>
  </div>
</template>

<script>
import authApi from '@/api/auth.js';
import { mapActions } from "vuex";

export default {
  data: function () {
    return {
      username: '',
      password: '',
      error: '',
      remind: false,
      formClass: '',
      email: '',
    };
  },
  mounted: function () {
    this.$refs.login.focus();
  },
  methods: {
    ...mapActions('auth', [
      'login',
      'getUser',
    ]),
    breadcrumb() {
      return this.$t('login.title');
    },
    loginSubmit() {
      const username = this.username;
      const password = this.password;
      this.login({
        password, username, grant_type: 'password',
      })
          .then(() => {
            this.formClass = 'bounceOut animated';
            this.getUser().then(() => {
              this.$router.push({ name: 'home' });
            });
          })
          .catch(error => {
            this.error = JSON.parse(error.response.data.error).error.details[0].message
          });
    },
    remindPass() {
      authApi.remindPassword(this.$http, this.username, this.email)
          .then(response => {
            if (response.data.success) {
              this.remind = false;
              this.error = this.$t('remind.sent');
            } else {
              this.error = this.$t('remind.error');
            }
          });
    }
  }
}
</script>
<style scoped>
.login {
  margin-top: 100px;
}

img {
  max-width: 100%;
}

form {
  -webkit-box-shadow: 0 0 8px 0 rgba(200, 200, 200, 0.8);
  -moz-box-shadow: 0 0 8px 0 rgba(200, 200, 200, 0.8);
  box-shadow: 0 0 8px 0 rgba(200, 200, 200, 0.8);
  background: white;
  padding: 2px;
  max-width: 495px;
  margin: 0 auto;
  overflow: hidden;
}

h3 {
  font-size: 20px;
  color: #2a4d2f;
  line-height: 60px;
  border-bottom: 1px solid #e3e3e3;
  text-align: center;
  margin-bottom: 30px;
}

h3 i {
  display: inline-block;
  width: 23px;
  height: 35px;
  background: url(../../images/login-ico.png) no-repeat center center;
  vertical-align: middle;
  margin-right: 10px;
}

form input.txt {
  width: 340px;
  box-sizing: border-box;
  margin: 18px auto;
  display: block;
  border: 1px solid #c6d9e5;
  font-size: 16px;
  color: #7a7a7a;
  padding: 10px !important;
  height: auto;
}

.btns {
  width: 340px;
  margin: 0 auto;
  position: relative;
  height: 40px;
  line-height: 40px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.btns .button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 30px;
  font-size: 16px;
}

p {
  text-align: center;
  font-weight: 300;
  color: #005691;
  line-height: 1.5em;
  padding: 20px 0;
}

.error {
  display: block;
  text-align: center;
  color: red;
  font-weight: bold;
}
</style>
