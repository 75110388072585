import { EventBus } from './bus.js';

class Units {
    static getAll() {
        return this.units
    }
    static getStep(unit) {
        return this.unitSteps[unit] || 0.01
    }
    static getName(unit) {
        return this.units[unit] || unit
    }
    static getFormat(unit) {
        return 'simple'
    }
}

Units.units = {
    hl :'hektolitr',
    l : 'litr',
    ml : 'ml',
    t : 'tona',
    kg : 'kg',
    g : 'g',
}
Units.unitSteps = {
    l : 0.01,
    kg : 0.01,
    hl : 0.01,
    t : 0.01,
    g : 1,
    ml : 1,
    ha : 0.01
}
Units.unitFormats = {
    l : 'stat',
    hl : 'stat',
    t : 'stat',
    kg : 'stat',
    g : 'simple',
    ml : 'simple',
    ha : 'stat'
}

export default Units