<template>
    <a href="#" @click.prevent="print()" class="button">drukuj <i class="ico ico-print" /></a>
</template>
<script> 
    export default {
        name :'print',
        props : {
           
        },
        data : function() {
            return {
                
            }
        },
        methods: {
           
            print() {
                window.print();
            }
        },
        watch : {
           
        }
    }
</script>
<style lang="scss">

</style>