import axiosApi from '@/api/axios';
export default {
    getOrders(params = {
        search: '',
        archive: false,
        date_from: '',
        date_to: '',
        status: '', // Enum: "pending" "in_progress" "finished"
    }) {
        return axiosApi.get('/orders', {
            params,
        });
    },
    createOrder(params = {
        crop_id: null,
        label_id: null,
        mixture_id: null,
        task_definition_id: null,
        field_id: null,
        vehicle_id: null,
        machine_id: null,
        employee_id: null,
        fertilizer_id: null,
        employer_comments: "string",
        comments: "string",
        scheduled_cultivated_area: null,
        realized_cultivated_area: null,
        machine_width: 0,
        label_norm: 0,
        closed_at: "string"
    }) {
        return axiosApi.post('/orders', params);
    },
    updateOrder(id, params = {
        crop_id: 0,
        label_id: 0,
        mixture_id: 0,
        task_definition_id: 0,
        field_id: 0,
        vehicle_id: 0,
        machine_id: 0,
        employee_id: 0,
        fertilizer_id: 0,
        employer_comments: "string",
        comments: "string",
        scheduled_cultivated_area: 0,
        realized_cultivated_area: 0,
        machine_width: 0,
        label_norm: 0,
        closed_at: "string"
    }) {
        return axiosApi.put(`/orders/${id}`, params);
    },
    deleteOrder(id) {
        return axiosApi.delete(`/orders/${id}`);
    },
    completeOrder(id, params) {
        return axiosApi.put(`/orders/${id}/complete`, params);
    },
    partiallyCompleteOrder(id, params = { cultivated_area: 0 }) {
        return axiosApi.put(`/orders/${id}/partially_complete`, params);
    },
    updateOrderAssignment(id, params = { assignment_action: 'assign' }) {
        return axiosApi.put(`/orders/${id}/update_assignment`, params);
    }
}
