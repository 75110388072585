<template>
   <div>
       <p>Pomoc serwisu</p> 
   </div>
</template>
 
<script>
   export default {
       title : 'Pomoc',
       methods : {
         breadcrumb() {
            return this.$t('help');
         }
       }
   }
</script>
 
<style scoped>
 
</style>