<template>
    <span>
        <span class="yes" v-if="value" v-tooltip="'Włączono'">
            T
        </span>
        <span class="no" v-else v-tooltip="'Wyłączono'">
            N
        </span>
    </span>
</template>
<script> 
    export default {
        name :'enabled',
        props : {
            value : {
                type: Boolean
            }
        },
        data : function() {
            return {
            }
        }
    }
</script>
<style lang="scss">
.yes {
    color: #009e5f;
}
.no {
    color: #cc4242;
}
.grid-table > tbody > tr:hover > td {
    .no, .yes {
        color: white;
    }
}
</style>