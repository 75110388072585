import router from '@/routes';
import axiosApi from '@/api/axios';
import authApi from '@/api/auth';

const emptyUserState = {
    "id": null,
    "username": "",
    "name": "",
    "email": "",
    "roles": [],
    "latlng": [
        null,
        null
    ],
    "settings": []
};

const emptyLoginUserState = {
    username: '',
    password: '',
    grantType: 'password',
};

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('Auth-Token') || '',
        refreshToken: localStorage.getItem('Refresh-Token') || '',
        user: JSON.parse(localStorage.getItem('User'))
            || emptyUserState,

        loginUser: emptyLoginUserState,
    },
    mutations: {
        SET_USER_DATA(state, user) {
            state.user = user;
            localStorage.setItem('User', JSON.stringify(user));
        },
        SET_LOGIN_DATA(state, loginUser) {
            state.loginUser = loginUser;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('Auth-Token', token);
        },
        SET_REFRESH_TOKEN(state, token) {
            state.refreshToken = token;
            localStorage.setItem('Refresh-Token', token);
        },
        CLEAR_SESSION_DATA(state) {
            state.token = '';
            state.user = emptyUserState;
            state.totpSecret = '';
            localStorage.removeItem('Auth-Token');
            localStorage.removeItem('Refresh-Token');
            localStorage.removeItem('User');
        },
        CLEAR_LOGIN_DATA(state) {
            state.loginUser = emptyLoginUserState;
        },
    },
    actions: {
        login({ commit, dispatch }, userCredentials) {
            commit('SET_LOGIN_DATA', userCredentials);

            return authApi.authenticate(userCredentials)
                .then((resp) => {
                    commit('SET_TOKEN', resp.data.accessToken);
                    commit('SET_REFRESH_TOKEN', resp.data.refreshToken);
                    commit('CLEAR_LOGIN_DATA');
                    axiosApi.defaults.headers.common.Authorization = `Bearer ${resp.data.accessToken}`;
                })
        },
        logout({ commit }) {
            commit('CLEAR_SESSION_DATA');
            delete axiosApi.defaults.headers.common.Authorization;
        },
        setPassword({ dispatch }, params) {
            dispatch('loader/changeProcessingStatus', true, { root: true });

            authApi.setPassword(params)
                .then(() => {
                    dispatch('snackbar/showSnackbar', {
                        message: ['Password changed successfully'],
                        type: 'success',
                    }, { root: true });
                })
                .then(() => {
                    router.push({ name: 'Login' });
                })
                .catch((error) => {
                    dispatch('snackbar/showSnackbar', {
                        message: [error.response.data.errors[0]],
                        type: 'error',
                    }, { root: true });
                })
                .finally(() => {
                    dispatch('loader/changeProcessingStatus', false, { root: true });
                });
        },
        recoverPassword({ dispatch }, params) {
            return new Promise((resolve, reject) => {
                dispatch('loader/changeProcessingStatus', true, { root: true });

                authApi.recoverPassword(params)
                    .then(() => {
                        dispatch('snackbar/showSnackbar', {
                            message: ['We sent a link to your email address so you can change your password.'],
                            type: 'success',
                        }, { root: true });
                        resolve();
                    })
                    .catch((error) => {
                        dispatch('snackbar/showSnackbar', {
                            message: [error.response.data.errors[0]],
                            type: 'error',
                        }, { root: true });
                        reject();
                    })
                    .finally(() => {
                        dispatch('loader/changeProcessingStatus', false, { root: true });
                    });
            });
        },
        getUser({ commit }) {
            return authApi.getUser().then((response) => {
                commit('SET_USER_DATA', response.data);
            });
        },
        hasRole({ state }, role) {
            return state.user.roles.includes(role);
        }
    },
    getters: {
        isLoggedIn(state) {
            return !!state.token;
        },
        token(state) {
            return state.token;
        },
        user(state) {
            return state.user;
        },
    },
};
