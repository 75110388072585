import axiosApi from "@/api/axios";

export default {
    getSeasons(params) {
        return axiosApi.get("/seasons", {
        params,
        });
    },
    createSeason(params) {
        return axiosApi.post("/seasons", params);
    },
    updateSeason(id, params) {
        return axiosApi.put(`/seasons/${id}`, params);
    },
    deleteSeason(id) {
        return axiosApi.delete(`/seasons/${id}`);
    },
}
