<template>
  <div>
    <h1>{{ breadcrumb() }}</h1>
    <div class="edit-table">
      <div class="btn-bar">
        <div class="left">
          <a href="#" @click.prevent="edit = {}" class="button" v-if="hasRole('ROLE_EMPLOYER_MANAGER')">dodaj <i
              class="ico ico-add"></i></a>
          <archive-button v-model="archive"/>
        </div>
        <div class="right">
          <search v-model="search" @filter="filter()" :print="true"/>
        </div>
      </div>
      <div class="line"></div>

      <model-form v-if="edit" @refresh="load()" @saved="edit = false" @created="edit = false" :model="edit"
                  @exit="edit = false"></model-form>

      <div class="grid">
        <div class="head">
          <div class="name">Imię i nazwisko</div>
          <div class="position">Stanowisko</div>
          <div class="description">Opis</div>
          <div class="sign">Identyfikator</div>
          <div class="av">Udostępnienie</div>
        </div>
        <template v-if="grid.collection.length > 0">
          <template v-for="(position, i) in grid.collection">
            <div class="row" @click.prevent="edit = position" :key="i">
              <div class="name">
                <text-highlight :queries="highlight">{{ position.name }}</text-highlight>
              </div>
              <div class="position">
                <text-highlight :queries="highlight">{{ position.position }}</text-highlight>
              </div>
              <div class="description">
                <description :queries="highlight" :value="position.description"/>
              </div>
              <div class="sign ">
                <text-highlight :queries="highlight" class="pre">{{ position.identifier }}</text-highlight>
              </div>
              <div class="av center btn-cell">
                <enabled :value="position.enabled"/>
                <a href="#"><i class="icon-edit"></i></a>
              </div>
            </div>
          </template>
        </template>
        <div class="row" v-else>
          <div class="empty" colspan="13"><span v-if="$route.query.search">Nic nie znaleziono. <button type="button"
                                                                                                       class="button"
                                                                                                       @click.prevent="clearFilter()">usuń filtr</button></span><span
              v-else>Lista jest pusta</span></div>
        </div>
      </div>
      <paginator :pages="grid.pagination.pageCount" :active="grid.pagination.page" @click="onPage"/>
    </div>
  </div>
</template>

<script>
import ModelForm from './form/Employer'
import TextHighlight from 'vue-text-highlight'
import Paginator from '../util/Paginator'
import Search from '../util/Search'
import employeesApi from "@/api/employees";
import { mapActions } from "vuex";

export default {
  components: { ModelForm, TextHighlight, Paginator, Search },
  data() {
    return {
      edit: false,
      search: this.$route.query.search,
      archive: this.$route.query.archive == 'true',
      grid: {
        collection: [],
        pagination: {
          itemsLength: 0,
          page: 1,
          pageCount: 1,
        }
      }
    }
  },
  watch: {
    $route() {
      this.search = this.$route.query.search;
      this.archive = this.$route.query.archive == 'true';
      this.load();
    },
    archive() {
      this.updateQuery({
        page: 1,
        archive: this.archive ? 'true' : 'false'
      })
    }
  },
  computed: {
    highlight() {
      if (typeof this.search != 'string') {
        return '';
      }
      return this.search;
    }
  },
  created() {
    this.load();
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    breadcrumb() {
      return 'Pracownicy';
    },
    clearFilter() {
      this.search = null;
      this.filter();
    },
    onPage(page) {
      this.updateQuery({
        page: page
      })
    },
    filter() {
      this.updateQuery({
        search: this.search,
        page: 1
      })
    },
    updateQuery(params) {
      let query = Object.assign({}, this.$route.query, params);
      if (query.page === 1) {
        delete query.page;
      }
      if (!query.search) {
        delete query.search;
      }
      this.$router.push({ query: query }).catch(() => {
        this.load();
      });
    },
    load() {
      employeesApi.getEmployees({
        query: this.$route.query.search,
        page: this.$route.query.page || 1,
        archive: this.archive
      }).then((resp) => {
        this.grid = resp.data;
      });
    }
  },
}
</script>

<style scoped>
div.name {
  width: 150px;
}

div.av {
  width: 90px;
}

div.sign {
  width: 150px;
}

div.position {
  width: 150px;
}
</style>
