<template>
  <div>
    <h1>{{ breadcrumb() }}</h1>
    <div class="edit-table">
      <div class="btn-bar">
        <div class="left">
          <a v-if="hasRole('ROLE_FIELD_MANAGER')" href="#" @click.prevent="create = true" class="button">dodaj nowe
            <i class="ico ico-add"></i></a>
          <a v-if="hasRole('ROLE_FIELD_MANAGER')" href="#" @click.stop="importModal = true" class="button">dodaj z
            AgroSmart <i class="ico ico-add"></i></a>
          <archive-button v-model="archive"/>
        </div>
        <div class="right">
          <search v-model="search" @filter="filter()" :print="true"/>
        </div>
      </div>
      <div class="line"></div>

      <model-form v-if="create" @refresh="load()" @map="map = create; create = false" :model="create"
                  @exit="create = false" @created="create = false" :dictionaries="dictionaries"></model-form>

      <div class="grid">
        <div class="head">
          <div class="img"></div>
          <div class="name">Nazwa</div>
          <div class="pe num" v-tooltip="'Powierzchnia ewidencyjna'">PE ha</div>
          <div class="pu num" v-tooltip="'Powierzchnia uprawna'">PU ha</div>
          <div class="season">Sezon</div>
          <div class="crop">Uprawa</div>
          <div class="label">Etykieta</div>
          <div class="norm">Norma</div>
          <div class="description">Opis</div>
          <div class="av">Udostępnienie</div>
        </div>
        <template v-if="grid.collection.length > 0">
          <template>
            <div class="row" @click.prevent="create = position" v-for="(position, i) in grid.collection" :key="i">
              <div class="img">
                                <span class="map" @click.prevent.stop="map = position">
                                    <img :src="'../api/field/'+ position.id + '/map?_token='+ token + '&' + time()"
                                         alt="" @mouseover="onMap(position, $event)" @mouseleave="over = null"/>
                                </span>
              </div>
              <div class="name">
                <text-highlight :queries="highlight">{{ position.name }}</text-highlight>
              </div>
              <div class="pe num">{{ $n(position.cadastralArea, 'simple') }}</div>
              <div class="pu num">{{ $n(position.cultivatedArea, 'simple') }}</div>
              <div class="season"><span v-if="position.currentCultivation"><text-highlight
                  :queries="highlight">{{ position.currentCultivation.season.name }}</text-highlight></span><span
                  v-else>-</span></div>
              <div class="crop"><span v-if="position.currentCultivation"><text-highlight
                  :queries="highlight">{{ position.currentCultivation.crop.name }}</text-highlight></span><span
                  v-else>-</span></div>
              <div class="label"><span v-if="position.currentCultivation"><text-highlight
                  :queries="highlight">{{ position.currentCultivation.label.name }}</text-highlight></span><span v-else>-</span>
              </div>
              <div class="norm num"><span
                  v-if="position.currentCultivation">{{ $n(position.currentCultivation.norm, 'simple') }}</span><span
                  v-else>-</span></div>
              <div class="description">
                <description :queries="highlight" :value="position.description"/>
              </div>
              <div class="av center btn-cell">
                <enabled :value="position.enabled"/>
                <a href="#"><i class="icon-edit"></i></a>
              </div>
            </div>
          </template>
        </template>
        <div class="row" v-else>
          <div class="empty" colspan="11">
            <span v-if="$route.query.search">
              Nic nie znaleziono.
              <button type="button"
                      class="button"
                      @click.prevent="clearFilter()"
              >
                usuń filtr
              </button>
            </span>
            <span v-else>Lista jest pusta</span></div>
        </div>
      </div>
      <paginator :pages="grid.pages" :active="grid.page" @click="onPage"/>
    </div>
    <small-map v-show="over" ref="smallmap" class="small-map"/>
    <import-field v-if="importModal" @selected="selectedFieldDefinition" @close="importModal = false"/>
    <field-map v-if="map" :field="map" @refresh="load()" @close="map=false" @edit="create = map; map = false"/>
  </div>
</template>

<script>
import fieldsApi from '@/api/fields'
import labelsApi from '@/api/labels'
import cropsApi from '@/api/crops'
import seasonsApi from '@/api/seasons'
import ModelForm from '@/components/agro/form/Field'
import TextHighlight from 'vue-text-highlight'
import Paginator from '@/components/util/Paginator'
import Search from '@/components/util/Search'
import ImportField from '@/components/agro/ImportField'
import FieldMap from '@/components/widget/FieldMap'
import SmallMap from '@/components/widget/SmallMap'
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ModelForm, TextHighlight, Paginator, Search, ImportField, FieldMap, SmallMap },
  created() {
    this.load()
    this.loadDictionaries()
  },
  data() {
    return {
      create: false,
      search: this.$route.query.search,
      importModal: false,
      map: false,
      over: false,
      archive: this.$route.query.archive !== 'false',
      dictionaries: {
        crops: [],
        labels: [],
        seasons: []
      },
      grid: {
        collection: [],
        pagination: {
          itemsLength: 0,
          page: 1,
          pageCount: 1,
        },
      }
    }
  },
  watch: {
    $route() {
      this.search = this.$route.query.search;
      this.load();
    },
    archive() {
      this.updateQuery({
        page: 1,
        archive: this.archive ? 'true' : 'false'
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['token']),
    highlight() {
      if (typeof this.search != 'string') {
        return '';
      }
      return this.search;
    },
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    selectedFieldDefinition(definition) {
      this.importModal = false
      this.create = {
        definition
      };
    },
    clearFilter() {
      this.search = null;
      this.filter();
    },
    onPage(page) {
      this.updateQuery({
        page: page
      })
    },
    filter() {
      this.updateQuery({
        search: this.search,
        page: 1
      })
    },
    updateQuery(params) {
      let query = Object.assign({}, this.$route.query, params);
      if (query.page === 1) {
        delete query.page;
      }
      if (!query.search) {
        delete query.search;
      }
      this.$router.push({ query: query }).catch(() => {
        this.load();
      });
    },
    loadDictionaries() {
      cropsApi.getCrops({
        archive: 'false',
        own: 'true'
      }).then((resp) => {
        this.dictionaries.crops = resp.data.collection.map((item) => {
          return {
            id: item.id,
            name: item.crop.name,
          }
        })
      })
      seasonsApi.getSeasons({
        archive: 'false'
      }).then((resp) => {
        this.dictionaries.seasons = resp.data.collection
      })
      labelsApi.getLabels({
        archive: 'false',
        own: 'true'
      }).then((resp) => {
        this.dictionaries.labels = resp.data.collection
      })
    },
    load() {
      fieldsApi.getFields({
        query: this.$route.query.search,
        page: this.$route.query.page || 1,
        archive: this.$route.query.archive
      }).then((resp) => {
        resp.data.collection.forEach(function (item) {
          item.edit = false;
        })
        this.grid = resp.data;
      });
    },
    breadcrumb() {
      return 'Pola uprawne';
    },
    saved(i, old, item) {
      old.edit = false;
      this.grid.data[i] = { ...item };
      this.grid.data[i].edit = false;
    },
    time() {
      return new Date().getTime()
    },
    onMap(position, e) {
      if (position && position.definition && position.definition.geom && position.definition.geom.length) {
        this.over = position
        let b = e.target.parentNode.getBoundingClientRect()
        let c = e.target.parentNode.parentNode
        this.$refs.smallmap.$el.style.top = (c.offsetTop - 10) + 'px'
        this.$refs.smallmap.$el.style.left = '40px'
        this.$refs.smallmap.refresh(position)
      } else {
        this.over = null
      }

    }
  },
}
</script>
<style scoped lang="scss">
@import '~/css/vars.scss';

div.name {
  width: 150px;
}

div.pe, div.pu {
  width: 80px;
}

div.season, div.crop, div.label {
  width: 100px;
}

div.norm {
  width: 50px;
}

div.img {
  width: 30px;
  overflow: visible !important;
}

.row:hover {
  z-index: 30;
}

div.av {
  width: 90px;
}

span.map {
  position: relative;
  display: block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: #4F805D;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  &:hover {
    z-index: 100;
  }
}

.small-map {
  position: absolute;
}

.row div.img {
  padding-top: 0px;
  padding-bottom: 0px;
}

@media screen and (max-width: $tablet1) {
  div.pe, div.norm {
    display: none;
  }
}
</style>
