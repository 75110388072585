<template>
  <modal class="modal-form" @exit="$emit('exit')">
    <template v-slot:header>
      <h2 v-if="position.id">Edycja pojazdu <strong>{{ position.name }}</strong></h2>
      <h2 v-else>Nowy pojazd <strong>{{ position.name }}</strong></h2>
    </template>
    <template v-slot:body>
      <form @submit.stop.prevent="save" class="form-table">
        <div class="field-row">

          <div class="form-group name">
            <label>Nazwa</label>
            <div class="form-text">
              <input-wrapper :error="errors.name">
                <input type="text" class="text" v-model="position.name" :disabled="!editEnabled" ref="first"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group norm">
            <label>Szerokość</label>
            <div class="form-text">
              <input-wrapper :error="errors.width">
                <input type="number" lang="pl" step=".01" class="text" :disabled="!editEnabled"
                       v-model="position.width"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group quantity-group">
            <label>Zadanie</label>
            <div class="form-text">
              <input-wrapper :error="errors.taskDefinition">
                <v-select append-to-body :options="taskDefinitions" v-model="position.taskDefinition" label="name"
                          :disabled="!editEnabled" multiple></v-select>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group norm">
            <label>Marka - model</label>
            <div class="form-text">
              <input-wrapper :error="errors.model">
                <input type="text" class="text" v-model="position.model" :disabled="!editEnabled"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group norm">
            <label>Rocznik</label>
            <div class="form-text">
              <input-wrapper :error="errors.productionYear">
                <input type="number" lang="pl" step="1" min="1500" max="3000" class="text" :disabled="!editEnabled"
                       v-model="position.productionYear"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group groups">
            <label>Opis</label>
            <div class="form-text">
              <input-wrapper :error="errors.description">
                <textarea-autosize v-model="position.description" :disabled="!editEnabled"></textarea-autosize>
              </input-wrapper>
            </div>
          </div>

        </div>
      </form>
      <confirm v-if="remove" @close="remove=false" @ok="onRemove">
        Zostanie usunięty pojazd <strong>{{ position.name }}</strong>
      </confirm>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left form-table">
          <div class="field-row">
            <div class="form-group simple-group">
              <label>Dostępność</label>
              <div class="form-control">
                <label>
                  <input type="checkbox" :disabled="!editEnabled" name="check" id="position-enabled"
                         v-model="position.enabled"/>
                  włącz
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <button v-if="editEnabled" type="submit" class="button" @click="save">
            zapisz <i class="ico ico-save" />
          </button>
          <button type="button" @click.prevent="$emit('exit')" class="button">anuluj</button>
          <button type="button" v-if="editEnabled && model" @click.prevent="remove = true" class="button button-danger">
            usuń <i class="ico ico-remove"></i></button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import tasksApi from '@/api/tasks';
import machinesApi from '@/api/machines';
import InputWrapper from '../../util/Input';
import Confirm from '../../util/Confirm';
import Modal from '../../util/Modal';
import { mapActions } from "vuex";

export default {
  props: { model: null },
  components: { InputWrapper, Confirm, Modal },
  mounted() {
    this.$bus.$emit('focus', this.$refs.first);
    this.load();
  },
  data() {
    const tmp = this.model || {
      name: '',
      description: '',
      productionYear: null,
      model: null,
      taskDefinition: [],
      width: null,
      enabled: true
    };
    return {
      position: { ...tmp },
      errors: {},
      remove: false,
      norm: false,
      taskDefinitions: [],
      editEnabled: this.hasRole('ROLE_MACHINE_MANAGER')
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    load() {
      tasksApi.getTasks().then((resp) => {
        this.taskDefinitions = resp.data.collection;
      });
    },
    onRemove() {
      machinesApi.deleteMachine(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh');
        this.$emit('exit');
      });
    },
    save() {
      if (!this.editEnabled) {
        return;
      }
      this.position.taskDefinitionIds = this.position.taskDefinition.map((task) => task.id);
      let promise = null;
      if (this.model && this.model.id) {
        promise = machinesApi.updateMachine(this.model.id, this.position);
      } else {
        promise = machinesApi.createMachine(this.position);
      }

      promise.then(() => {
        this.$emit('refresh');
        this.errors = {};
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        if (this.model) {
          this.$emit('saved', this.position);
        } else {
          this.$emit('created', this.position);
        }
      }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors = this.$errors.transform(err.response.data)
          this.$notify({
            title: this.$t('error.form'),
            type: 'error'
          })

        } else {
          this.$notify({
            title: this.$t('error.connection'),
            type: 'error'
          })
        }
      })
    }
  },
  watch: {}
}
</script>
<style scoped lang="scss">
input[type=text], input[type=number], select {
  width: 100%;
}

form {
  color: black;
}

.check {
  white-space: nowrap;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

.line {
  margin-bottom: 10px;
}

.row {
  padding-bottom: 10px;

  .form-addon {
    width: 60px;
    font-size: 14px;
  }

  .form-input {
    padding-right: 10px;
    flex: 1;
  }
}
</style>
