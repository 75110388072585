<template>
  <ul class="menu">
    <li v-if="home" class="home">
      <router-link :to="{name : 'home'}">Pulpit</router-link>
    </li>
    <li v-if="hasRole('ROLE_FIELD')">
      <router-link :to="{name : 'fields'}"><i class="fields"></i>Pola uprawne</router-link>
    </li>
    <li v-if="hasRole('ROLE_TASK')">
      <router-link :to="{name : 'orders'}"><i class="orders"></i>Zlecenia</router-link>
    </li>
    <li v-if="hasRole(['ROLE_CROP', 'ROLE_LABEL'])" class="sub"
        :class="['crops', 'crops'].includes($route.name) ? 'active' : ''">
      <a href="#" :class="['crops', 'labels'].includes($route.name) ? 'active' : ''">Rośliny</a>
      <ul>
        <li v-if="hasRole('ROLE_CROP')">
          <router-link :to="{name : 'crops'}"><i class="crops"></i> Rośliny uprawne</router-link>
        </li>
        <li v-if="hasRole('ROLE_LABEL')">
          <router-link :to="{name : 'labels'}"><i class="labels"></i>Etykiety</router-link>
        </li>
      </ul>
    </li>
    <li class="sub" v-if="hasRole(['ROLE_MIXTURE', 'ROLE_PLANT_PROTECTION', 'ROLE_FERTILIZER'])">
      <a href="#"
         :class="['plantProtections', 'fertilizers', 'plantProtectionsMixtures'].includes($route.name) ? 'active' : ''">ŚOR/Nawozy</a>
      <ul>
        <li v-if="hasRole('ROLE_MIXTURE')">
          <router-link :to="{name : 'plantProtectionsMixtures'}"><i class="plantProtections"></i>Mieszaniny
          </router-link>
        </li>
        <li v-if="hasRole('ROLE_PLANT_PROTECTION')">
          <router-link :to="{name : 'plantProtections'}"><i class="plantProtections"></i>Środki ochrony</router-link>
        </li>
        <li v-if="hasRole('ROLE_FERTILIZER')">
          <router-link :to="{name : 'fertilizers'}"><i class="fertilizers"></i>Nawozy</router-link>
        </li>
      </ul>
    </li>
    <li class="sub"
        v-if="hasRole(['ROLE_TASK_DEFINITION','ROLE_PLANT_SEASON', 'ROLE_TASK_DEFINITION', 'ROLE_MACHINE', 'ROLE_VEHICLE', 'ROLE_EMPLOYER'])">
      <a href="#"
         :class="['taskDefinitions', 'machines', 'vehicles', 'employers', 'seasons'].includes($route.name) ? 'active' : ''">Ustawienia</a>
      <ul>
        <li v-if="hasRole('ROLE_PLANT_SEASON')">
          <router-link :to="{name : 'seasons'}"><i class="fields"></i>Sezony</router-link>
        </li>
        <li v-if="hasRole('ROLE_TASK_DEFINITION')">
          <router-link :to="{name : 'taskDefinitions'}"><i class="tasks"></i>Zadania</router-link>
        </li>
        <li v-if="hasRole('ROLE_MACHINE')">
          <router-link :to="{name : 'machines'}"><i class="machines"></i>Maszyny</router-link>
        </li>
        <li v-if="hasRole('ROLE_VEHICLE')">
          <router-link :to="{name : 'vehicles'}"><i class="machines"></i>Pojazdy</router-link>
        </li>
        <li v-if="hasRole('ROLE_EMPLOYER')">
          <router-link :to="{name : 'employers'}"><i class="employers"></i>Pracownicy</router-link>
        </li>
      </ul>
    </li>
    <li v-if="hasRole('ROLE_ARCHIVE')"><a href="#"><i class="orders"></i>Archiwum</a></li>
  </ul>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    home: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
  }
}
</script>
