<template>
    <div>
        <modal>
            <template slot="header">
                Wybierz
            </template>
            <div class="body" slot="body">
                <h4 v-if="list === null">
                    Pobieranie...
                </h4>
                <p v-if="list.length == 0">
                    Wszystkie obszary są już zaimportowane
                </p>
                <ul v-else>
                    <li v-for="field in list" @click="$emit('selected', field)" :key="field.id">
                        {{ field.name }}
                    </li>
                </ul>
            </div>
            <div class="foot" slot="footer">
                <slot name="footer">
                  <button class="button small-button" type="button" @click="$emit('close');">
                        anuluj
                  </button>
                </slot>
            </div>
        </modal>
    </div>
</template>
<script> 
    export default {
        name :'import-field',
        props : {
        },
        data : function() {
            return {
                list : null
            }
        },
        mounted() {
            this.$http.get('field/agrofields', {
                
            }).then((resp) => {
                this.list = resp.data.fields;
            });
        }
    }
</script>
<style lang="scss" scoped>
    li {
        display: block;
        padding: 4px;
        cursor: pointer;
        overflow: auto;
        &:nth-child(even) {
            background: #eee;
        }
    }
    .body {
        max-height: 50vh;
        overflow: auto;
    }
</style>