import axiosApi from "@/api/axios";

export default {
    getMixtures() {
        return axiosApi.get("/mixtures");
    },
    createMixture(params) {
        return axiosApi.post("/mixtures", params);
    },
    updateMixture(id, params) {
        return axiosApi.put(`/mixtures/${id}`, params);
    },
    deleteMixture(id) {
        return axiosApi.delete(`/mixtures/${id}`);
    },
    getMixturePlantProtections(params) {
        return axiosApi.get("/plant_protections", { params });
    },
    creatPlantProtection(params) {
        return axiosApi.post("/plant_protections", params);
    },
    updatePlantProtection(id, params) {
        return axiosApi.put(`/plant_protections/${id}`, params);
    },
    deleteMixturePlantProtection(id) {
        return axiosApi.delete(`/mixture_plant_protections/${id}`);
    },
    getMixtureFertilizers(params) {
        return axiosApi.get("/fertilizers", { params });
    },
    createMixtureFertilizer(params) {
        return axiosApi.post("/fertilizers", params);
    },
    updateMixtureFertilizer(id, params) {
        return axiosApi.put(`/fertilizers/${id}`, params);
    },
    deleteMixtureFertilizer(id) {
        return axiosApi.delete(`/fertilizers/${id}`);
    },
}
