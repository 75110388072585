<template>
  <div>
    <h1>{{ breadcrumb() }}</h1>
    <model-list ref="list" @rowclick="model = $event" :route=true :routeParams="routeParams" :av="true"
                :params="gridParams">
      <a href="#" v-if="hasRole('ROLE_CROP_MANAGER')" @click.prevent="model = {}" class="button">dodaj <i
          class="ico ico-add"></i></a>
      <archive-button v-model="routeParams.archive" @input="refresh()"/>
    </model-list>
    <model-form v-if="model" :model="model" @exit="model = false" @refresh="refresh()"/>
  </div>
</template>

<script>
import ModelForm from './form/Label'
import ModelList from './list/Label'
import Search from '../util/Search'
import { mapActions } from "vuex";

export default {
  components: { ModelForm, Search, ModelList },
  data() {
    return {
      model: false,
      add: false,
      gridParams: {
        own: true
      },
      searchParams: {
        import: true
      },
      routeParams: {
        archive: false
      }
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    breadcrumb() {
      return 'Etykiety';
    },
    refresh() {
      this.model = false
      this.$refs.list.load()
    }
  }
}
</script>

<style scoped>

</style>
