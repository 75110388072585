<template>
  <modal class="modal-form field-form" @exit="$emit('exit')">
    <template v-slot:header>
      <span v-if="position.id">Edycja pola <strong>{{ position.name }}</strong></span>
      <span v-else>Nowe pole <strong>{{ position.name }}</strong></span>
    </template>
    <template v-slot:body>

      <form @submit.stop.prevent="save" class="form-table field-form">
        <div class="field-row">
          <div class="form-group">
            <label>Nazwa</label>
            <div class="form-control">
              <input-wrapper :error="errors.name">
                <input type="text" class="text" :disabled="!editEnabled" v-model="position.name" ref="first"/>
              </input-wrapper>
            </div>
          </div>
          <div class="field-row simple-group">
            <div class="form-group">
              <label>Powierzchnia ewidencyjna</label>
              <div class="form-control">
                <input-wrapper :error="errors.cadastralArea">
                  <field-unit class="text" :disabled="!editEnabled" v-model="position.cadastralArea" unit="ha"/>
                </input-wrapper>
              </div>
              <span class="unit">ha</span>
            </div>
            <div class="form-group">
              <label>Powierzchnia uprawna</label>
              <div class="form-control">
                <input-wrapper :error="errors.cultivatedArea">
                  <field-unit :disabled="!editEnabled" class="text" v-model="position.cultivatedArea" unit="ha"/>
                </input-wrapper>
              </div>
              <span class="unit">ha</span>
            </div>
          </div>
          <div class="form-group wide-group">
            <label>Opis</label>
            <div class="form-control">
              <textarea-autosize v-model="position.description" :disabled="!editEnabled"></textarea-autosize>
            </div>
          </div>
        </div>
        <table v-if="position.cultivations.length > 0" class="cultivations">
          <thead>
            <tr>
              <th style="width: 100px;">Sezon</th>
              <th style="width: 120px;">Roślina</th>
              <th style="width: 120px;">Etykieta</th>
              <th style="width: 80px;">Norma</th>
              <th style="width: 70px" class="nums" v-tooltip="'Powierzchnia uprawna'">PU [ha]</th>
              <th class="date">Rozpoczęcie</th>
              <th class="date">Siew</th>
              <th style="width: 90px;">Wydajność [ha]</th>
              <th style="width: 90px;">Zebrano [t]</th>
              <th class="date">Zakończenie</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(cultivation, index) in position.cultivations" :key="index">
            <td>
              <input-wrapper :error="errors['cultivations[' + index + '].season']">
                <v-select append-to-body :disabled="!editEnabled" :options="dictionaries.seasons"
                          v-model="cultivation.season" label="name"></v-select>
              </input-wrapper>
            </td>
            <td>
              <input-wrapper :error="errors['cultivations[' + index + '].crop']">
                <v-select append-to-body :disabled="!editEnabled" :options="dictionaries.crops"
                          v-model="cultivation.crop" label="name" @input="onCropChanged(cultivation)" />
              </input-wrapper>
            </td>
            <td>
              <input-wrapper :error="errors['cultivations[' + index + '].label']">
                <v-select append-to-body :disabled="!editEnabled" :options="cultivationLabels(cultivation)"
                          v-model="cultivation.label" label="name" @input="onLabelChanged(cultivation)"></v-select>
              </input-wrapper>
            </td>
            <td>
              <input-wrapper :error="errors['cultivations[' + index + '].norm']">
                <field-unit :disabled="!editEnabled" class="text" v-model="cultivation.norm"/>
              </input-wrapper>
            </td>
            <td>
              <input-wrapper :error="errors['cultivations[' + index + '].cultivatedArea']">
                <field-unit :disabled="!editEnabled" class="text" v-model="cultivation.cultivatedArea"/>
              </input-wrapper>
            </td>
            <td>
              <input-wrapper :error="errors['cultivations[' + index + '].startDate']">
                <datepicker :disabled="!editEnabled" v-model="cultivation.startDate" valueType="YYYY-MM-DD"
                            :clearable="false" format="DD.MM.YYYY"/>
              </input-wrapper>
            </td>
            <td>
              <input-wrapper :error="errors['cultivations[' + index + '].sowingDate']">
                <datepicker :disabled="!editEnabled" v-model="cultivation.sowingDate" valueType="YYYY-MM-DD"
                            :clearable="editEnabled" format="DD.MM.YYYY"></datepicker>
              </input-wrapper>
            </td>
            <td>
              <input-wrapper :error="errors['cultivations[' + index + '].efficiency']">
                <field-unit :disabled="!editEnabled" class="text" v-model="cultivation.efficiency"
                            @input="cultivation.collected = (cultivation.efficiency * cultivation.cultivatedArea).toFixed(2)"/>
              </input-wrapper>
            </td>
            <td>
              <input-wrapper :error="errors['cultivations[' + index + '].collected']">
                <field-unit :disabled="!editEnabled" class="text" v-model="cultivation.collected"
                            @input="cultivation.efficienty = (cultivation.collected / cultivation.cultivatedArea).toFixed(2)"/>
              </input-wrapper>
            </td>
            <td>
              <input-wrapper :error="errors['cultivations[' + index + '].endDate']">
                <datepicker :disabled="!editEnabled" v-model="cultivation.endDate" valueType="YYYY-MM-DD"
                            :clearable="editEnabled" format="DD.MM.YYYY"/>
              </input-wrapper>
            </td>
            <td>
              <button v-if="editEnabled" type="button" @click.prevent="removeCultivation = cultivation"
                      class="button button-danger">
                <span>usuń</span> <i class="ico ico-remove"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="btn-bar">
          <div class="left" />
          <div class="right" />
        </div>
        <div class="btn-bar">
          <div class="left" />
          <div class="right" />
        </div>
        <confirm v-if="remove" @close="remove=false" @ok="onRemove">
          Zostanie usunięte pole <strong>{{ position.name }}</strong>
        </confirm>
        <confirm v-if="removeCultivation" @close="removeCultivation=false" @ok="onRemoveCultivation">
          Zostanie usunięta uprawa <strong>{{ removeCultivation.season ? removeCultivation.season.name : '' }}</strong>
        </confirm>
      </form>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left form-table">
          <div class="field-row">
            <div class="form-group simple-group">
              <label>Dostępność</label>
              <div class="form-control">
                <label>
                  <input type="checkbox" :disabled="!hasRole('ROLE_PLANT_PROTECTION_MANAGER')" name="check"
                              id="position-enabled" v-model="position.enabled"/> włącz
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <button v-if="editEnabled" type="submit" @click.prevent="save()" class="button">
            zapisz <i class="ico ico-save" />
          </button>
          <button type="button" @click.prevent="$emit('exit')" class="button">anuluj</button>
          <button v-if="editEnabled" type="button" @click.prevent="createCultivation()" class="button">
            dodaj uprawę
          </button>
          <button type="button" v-if="editEnabled && model && model.id" @click.prevent="remove = true"
                  class="button button-danger">
            <span>usuń</span><i class="ico ico-remove"/>
          </button>
          <button type="button" v-if="model && model.id" @click.prevent="$emit('map')" class="button ">mapa</button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import InputWrapper from '../../util/Input'
import moment from 'moment'
import FieldUnit from '@/components/widget/FieldUnit'
import { mapActions } from "vuex";
import fieldsApi from '@/api/fields'

export default {
  props: { model: null, dictionaries: null },
  components: { InputWrapper, FieldUnit },
  data() {
    const tmp = {
      name: '',
      enabled: true,
      description: '',
      cultivatedArea: 0,
      cadastralArea: 0,
      cultivations: []
    };
    if (this.model && this.model.definition && tmp.name === '') {
      tmp.name = this.model.definition.name;
    }
    return {
      position: { ...tmp, ...JSON.parse(JSON.stringify(this.model)) },
      errors: {},
      remove: false,
      removeCultivation: false,
      editEnabled: !!this.hasRole('ROLE_FIELD_MANAGER')
    }
  },
  mounted() {
    if (this.$refs.first) {
      this.$bus.$emit('focus', this.$refs.first);
    }

    if (this.model && this.model.id) {
      this.position.cultivations.map((cultivation) => {
        if (cultivation.seasonId) {
          cultivation.season = this.dictionaries.seasons.find(s => s.id === cultivation.seasonId)
        }
        if (cultivation.cropId) {
          cultivation.crop = this.dictionaries.crops.find(c => c.id === cultivation.cropId)
        }
        if (cultivation.fieldId) {
          cultivation.field = this.dictionaries.fields.find(f => f.id === cultivation.fieldId)
        }
        if (cultivation.labelId) {
          cultivation.label = this.dictionaries.labels.find(l => l.id === cultivation.labelId)
        }
      })
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    cultivationLabels(cultivation) {
      if (cultivation.crop) {
        return this.dictionaries.labels.filter(l => l.crop.id && cultivation.crop.id === l.crop.id)
      }

      return this.dictionaries.labels
    },
    createCultivation() {
      this.position.cultivations.unshift({
        cadastralArea: this.position.cadastralArea,
        cultivatedArea: this.position.cultivatedArea,
        season: null,
        norm: null,
        label: null,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: null,
        sowingDate: null,
        efficiency: null,
        collected: null
      })
    },
    onLabelChanged(cultivation) {
      if (!cultivation.norm && cultivation.label) {
        cultivation.norm = cultivation.label.norm
      }
      if (cultivation.label && cultivation.label.crop) {
        cultivation.crop = cultivation.label.crop
      }
    },
    onCropChanged(cultivation) {
      if (cultivation.label?.crop?.id !== cultivation.crop?.id) {
        cultivation.label = null
      }
    },
    onRemove() {
      fieldsApi.deleteField(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh')
        this.$emit('close')
        this.$emit('exit')
      });

    },
    onRemoveCultivation() {
      this.position.cultivations = this.position.cultivations.filter((row) => {
        return row !== this.removeCultivation
      })
      this.removeCultivation = false
    },
    save() {
      if (!this.editEnabled) {
        return;
      }
      let promise = null;
      this.position = {
        ...this.position,
        cultivations: this.position.cultivations.map((cultivation) => {
          return {
            ...cultivation,
            seasonId: cultivation.season ? cultivation.season.id : null,
            cropId: cultivation.crop ? cultivation.crop.id : null,
            labelId: cultivation.label ? cultivation.label.id : null,
          }
        })
      }
      if (this.model?.id) {
        promise = fieldsApi.updateField(this.model.id, this.position);
      } else {
        promise = fieldsApi.createField(this.position);
      }

      promise.then(() => {
        this.$emit('refresh');
        this.errors = {};
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        if (this.model) {
          this.$emit('saved', this.position);
        } else {
          this.$emit('created', this.position);
        }
        this.$emit('exit')
      }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors = this.$errors.transform(err.response.data)
          this.$notify({
            title: this.$t('error.form'),
            type: 'error'
          })

        } else {
          this.$notify({
            title: this.$t('error.connection'),
            type: 'error'
          })
        }
      })
    },
  }
}
</script>
<style lang="scss">
@import '~/css/vars.scss';

.field-form {
  .mx-icon-calendar {
    display: none !important;
  }

  .vs--single .vs__search {
    position: absolute;
  }
}

@media screen and (max-width: $small) {
  .mx-datepicker {
    min-width: 85px;
  }
  .field-form .cultivations {
    .date {
      width: 80px;
    }

    .mx-datepicker {
      width: 85px;
    }
  }
}

@media screen and (max-width: $tablet1) {
  .field-form .cultivations .button {
    span {
      display: none;
    }

    .ico {
      margin: 0px;
    }
  }
}
</style>
<style scoped>
input[type=text], input[type=number], .numberfield, select {
  width: 100%;
  min-width: auto;
}

.form-group .unit {
  padding: 4px 0px 4px 4px;
  display: block;
}

.numberfield {
  min-width: 75px;
}

.form-table td, .form-table th {
  padding: 3px;
}

</style>
