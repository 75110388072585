<template>
  <div>
    <h1>{{ breadcrumb() }}</h1>
    <fertilizer-list ref="list" @rowclick="model = $event" :route=true :routeParams="routeParams" :av="true"
                     :params="gridParams">
      <a href="#" v-if="hasRole('ROLE_FERTILIZER_MANAGER')" @click.prevent="model = {}" class="button">dodaj nowy <i
          class="ico ico-add"></i></a>
      <a href="#" v-if="hasRole('ROLE_FERTILIZER_MANAGER')" @click.prevent="add = true" class="button">importuj <i
          class="ico ico-add"></i></a>
      <archive-button v-model="routeParams.archive" @input="refresh()"/>
    </fertilizer-list>
    <fertilizer-list v-if="add" :search="$refs.list.query.search"
                     @rowclick="model = $event, model.enabled=true, add = false" @exit="add = false" :modal=true
                     :route=false :av="false" :params="searchParams" exclude="own">
      <template v-slot:header>
        <h2>Dodaj z listy ministerstwa</h2>
      </template>
      <template v-slot:default>
        <a href="#" @click.stop="model = {}, add = false" class="button">dodaj spoza listy <i
            class="ico ico-add"></i></a>
      </template>
      <template v-slot:footer>
        <a href="#" @click.stop="add = false" class="button">anuluj <i class="ico ico-close"></i></a>
      </template>
    </fertilizer-list>
    <model-form v-if="model" :model="model" @exit="model = false" @refresh="refresh()"/>
  </div>
</template>

<script>
import ModelForm from './form/Fertilizer'
import FertilizerList from './list/Fertilizer'
import TextHighlight from 'vue-text-highlight'
import Paginator from '../util/Paginator'
import Search from '../util/Search'
import { mapActions } from "vuex";

export default {
  components: { ModelForm, TextHighlight, Paginator, Search, 'fertilizer-list': FertilizerList },
  data() {
    return {
      model: false,
      add: false,
      gridParams: {
        own: true
      },
      searchParams: {
        import: true
      },
      routeParams: {
        archive: false
      }
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    breadcrumb() {
      return 'Moje nawozy';
    },
    refresh() {
      this.model = false
      this.$refs.list.load()
    }
  }
}
</script>

<style scoped>
div.name {
  width: 200px;
}

div.group {
  width: 80px;
}

div.composition {
  width: 80px;
}

div.quantity {
  width: 100px;
}

div.pz {
  width: 100px;
}

div.av {
  width: 90px;
}
</style>
