<template>
    <span>
        {{ printable }}
    </span>
</template>
<script>
    import Units from '@/service/unit.js'
    export default {
        name :'unit',
        props : {
            value : null,
            unit : null
        },
        computed : {
            printable() {
                let str = ''
                if (this.value !== false && this.value !== null) {
                    let v = this.value;
                    if (typeof v == 'string') {
                        v = parseFloat(v)
                    }
                    str = this.$n(v, Units.getFormat(this.unit))
                }
                if (this.value !== false && this.value !== null && this.unit) {
                    str += ' '
                }
                if (this.unit) {
                    str += Units.getName(this.unit)
                }

                return str
            }
        }
    }
</script>
