<template>
   <div>
     <pulse-loader loading="loading" :color="color" :size="size"></pulse-loader>
   </div>
</template>
 
<script>
   import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
   export default {
    components : {PulseLoader},
    props: {
        size:{
            type: String,
            default: '8px'
        },
        color : {
            type: String,
            default: '#0065a2'
        }
    }
    
   }
</script>
 
<style scoped>
 div {
    box-size: content-box;
    text-align: center;
 }
</style>