import axiosApi from '@/api/axios';

export default {
    authenticate(userCredentials) {
        return axiosApi.post('/oauth/token', userCredentials);
    },
    refreshToken(refreshToken) {
        return axiosApi.post('/oauth/token', {
            grantType: 'refresh_token',
            refreshToken: refreshToken,
        });
    },
    revokeToken(token) {
        return axiosApi.post('/oauth/revoke', { token });
    },
    setPassword(params) {
        return axiosApi.post('/users/set_password', params);
    },
    recoverPassword(params) {
        return axiosApi.post('/users/reset_password', params);
    },
    changePassword(params) {
        return axiosApi.post('/users/change_password', params);
    },
    getUser() {
        return axiosApi.get('/me');
    },
};
