<template>
    <modal>
        <template name="header" v-slot:header>
            Jesteś pewny?
        </template>
        <template name="body" v-slot:body>
            <slot></slot>
        </template>
        <template name="footer" v-slot:footer>
            <button class="button button-danger" type="button" @click.stop="$emit('ok')">
            TAK
          </button>
          <button class="button button-small" type="button" @click.stop="$emit('close')">
            NIE
          </button>
        </template>
    </modal>
</template>
<script>
    export default {
        props: {
            subtitle : false
        }
    }
</script>