<template>
  <modal class="modal-form" @exit="$emit('exit')">
    <template v-slot:header>
      <h2 v-if="model.id">Edycja nawozu {{ model.name }}</h2>
      <h2 v-else>
        Nowy nawóz {{ model.name }}
      </h2>
    </template>
    <template v-slot:body>
      <form @submit.stop.prevent="save" class="form-table">
        <h4>Informacje o nawozie</h4>
        <div class="field-row">
          <div class="form-group groups">
            <label>Nazwa</label>
            <div class="form-control">
              <input-wrapper :error="errors.name" v-if="own">
                <input type="text" v-model="position.name" :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')"
                       ref="name"/>
              </input-wrapper>
              <span class="input-value" v-else>
                {{ position.name }}
              </span>
            </div>
          </div>
          <div class="form-group groups">
            <label>Klasyfikacja</label>
            <div class="form-control">
              <input-wrapper :error="errors.fertilizerGroup" v-if="own">
                <select v-model="position.fertilizerGroup" :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')">
                  <option :value="fertilizerGroup" v-for="fertilizerGroup in groups" :key="fertilizerGroup.id">
                    {{ fertilizerGroup.name }}
                  </option>
                  <option :value="null">Inne</option>
                </select>
              </input-wrapper>
              <span class="input-value" v-else>
                {{ position.fertilizerGroup ? position.fertilizerGroup.name : '-' }}
              </span>
            </div>
          </div>
          <div class="form-group groups">
            <label>Skład</label>
            <div class="form-control">
              <input-wrapper :error="errors.composition">
                <ul class="composition">
                  <li>
                    <strong>N</strong>
                    <input v-if="own" type="number" lang="pl" :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')"
                           class="text" step="0.01" v-model="position.composition.n"/>
                    <span class="input-value" v-else>
                      {{ position.composition.n ? $n(position.composition.n, 'simple') : '-' }}
                    </span>
                  </li>
                  <li>
                    <strong>P<sub>2</sub>O<sub>5</sub></strong>
                    <input type="number" lang="pl" :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')" class="text"
                           v-if="own" step="0.01" v-model="position.composition.p"/>
                    <span class="input-value" v-else>
                      {{ position.composition.p ? $n(position.composition.p, 'simple') : '-' }}
                    </span>
                  </li>
                  <li>
                    <strong>K<sub>2</sub>O</strong>
                    <input type="number" lang="pl" :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')" class="text"
                           v-if="own" step="0.01" v-model="position.composition.b"/>
                    <span class="input-value" v-else>
                      {{ position.composition.b ? $n(position.composition.b, 'simple') : '-' }}
                    </span>
                  </li>
                  <li>
                    <strong>CaO</strong>
                    <input type="number" lang="pl" :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')" class="text"
                           v-if="own" step="0.01" v-model="position.composition.ca"/>
                    <span class="input-value" v-else>
                      {{ position.composition.ca ? $n(position.composition.ca, 'simple') : '-' }}
                    </span>
                  </li>
                  <li>
                    <strong>MgO</strong>
                    <input type="number" lang="pl" :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')" class="text"
                           v-if="own" step="0.01" v-model="position.composition.mg"/>
                    <span class="input-value" v-else>
                      {{ position.composition.mg ? $n(position.composition.mg, 'simple') : '-' }}
                    </span>
                  </li>
                </ul>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group groups">
            <label>Opis</label>
            <div class="form-control">
              <textarea-autosize v-model="position.description" :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')"
                                 v-if="own"/>
              <span class="input-value" v-else>
                {{ position.description }}
              </span>
            </div>
          </div>
        </div>
        <h4>Magazyn</h4>
        <div class="field-row">
          <div class="form-group quantity-group">
            <label>Stan</label>
            <div class="form-control">
              <div class="simple-row">
                <input-wrapper :error="errors.stockQuantity">
                  <field-unit v-model="position.stockQuantity" :unit="position.stockUnit"
                              :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')" ref="first"/>
                </input-wrapper>
                <select v-model="position.stockUnit" class="auto-width" :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')">
                  <option v-for="(name, unit) in units" :key="unit" :value="unit">{{ name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group quantity-group">
            <label>PZ Ha</label>
            <div class="form-control">
              <div class="simple-row">
                <input-wrapper :error="errors.max">
                  <field-unit v-model="position.max" :unit="position.stockUnit"
                              :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')"/>
                </input-wrapper>
                <span class="input-value">
                  <unit :unit="position.stockUnit" :value="false"/>
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <confirm v-if="remove" @close="remove=false" @ok="onRemove">
        Zostanie usunięty nawóz <strong>{{ position.name }}</strong>
      </confirm>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <print/>
        <div class="left form-table">
          <div class="field-row">
            <div class="form-group simple-group">
              <label>Dostępność</label>
              <div class="form-control">
                <label><input type="checkbox" :disabled="!hasRole('ROLE_FERTILIZER_MANAGER')" name="check"
                              id="position-enabled" v-model="position.enabled"/> włącz</label>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <button type="button" v-if="hasRole('ROLE_FERTILIZER_MANAGER')" @click="save" class="button">zapisz <i
              class="ico ico-save"></i></button>
          <button type="button" @click.prevent="$emit('exit')" class="button">anuluj</button>
          <button type="button" v-if="hasRole('ROLE_FERTILIZER_MANAGER') && model.stockId"
                  @click.prevent="remove = true" class="button button-danger">usuń <i class="ico ico-remove"></i>
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import InputWrapper from '@/components/util/Input'
import FieldUnit from '@/components/widget/FieldUnit'
import Unit from '@/components/widget/Unit'
import Units from '@/service/unit.js'
import Print from '@/components/util/Print'
import { mapActions } from "vuex"
import mixturesApi from '@/api/mixtures'
import filtersApi from '@/api/filters'

export default {
  props: {
    model: {
      type: Object,
      default: null
    }
  },
  components: { InputWrapper, FieldUnit, Unit, Print },
  data() {
    let tmp = this.model || {}
    tmp = { ...tmp }
    let def = {
      name: '',
      enabled: true,
      description: '',
      max: 1,
      stockUnit: 'l',
      stockQuantity: null,
      composition: {
        p: null,
        b: null,
        ca: null,
        k: null,
        mg: null,
      },
      fertilizerGroup: null,
    }
    return {
      units: Units.getAll(),
      position: {
        ...def,
        ...tmp,
        enabled: tmp.stockId > 0 ? tmp.enabled : true
      },
      errors: {},
      groups: [],
      filters: {},
      remove: false
    }
  },
  computed: {
    own() {
      return this.position.clientId !== ''
    }
  },
  created() {
    filtersApi.getFilters().then((response) => {
      this.filters = response.data
      this.groups = this.filters.fertilizerGroups
      this.position.fertilizerGroup = this.groups.find(g => g.id === this.position.fertilizerGroup?.id)
    })
  },
  mounted() {
    if (this.$refs.name) {
      this.$bus.$emit('focus', this.$refs.name)
    } else if (this.$refs.first) {
      this.$bus.$emit('focus', this.$refs.first)
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    onRemove() {
      mixturesApi.deleteMixtureFertilizer(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh');
        this.$emit('exit');
      });
    },
    save() {
      if (!this.hasRole('ROLE_FERTILIZER_MANAGER')) {
        return
      }
      let promise = null;
      this.position.fertilizerGroupId = this.position.fertilizerGroup?.id
      if (this.model && this.model.id) {
        promise = mixturesApi.updateMixtureFertilizer(this.model.id, this.position)
      } else {
        promise = mixturesApi.createMixtureFertilizer(this.position)
      }
      promise.then(() => {
        this.$emit('refresh');
        this.errors = {};
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('saved', this.position);
      }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors = this.$errors.transform(err.response.data)
          this.$notify({
            title: this.$t('error.form'),
            type: 'error'
          })
        } else {
          this.$notify({
            title: this.$t('error.connection'),
            type: 'error'
          })
        }
      })
    }
  },
}
</script>
<style scoped>
input[type=text], input[type=number] {
  width: 100%;
}

.composition input {
  width: 43px;
}

.composition strong {
  display: inline-block;
}

.composition li {
  display: inline-block;
}

.composition li strong {
  font-size: 14px;
  line-height: 22px;
}
</style>
