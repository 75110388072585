<template>
    <div class="description-cmp" v-tooltip.pre="value">
        <span v-if="!useHighlight" class="simple">{{ value }}</span>
        <text-highlight :queries="queries" class="pre" v-else>{{ value }}</text-highlight>
    </div>
</template>
<script> 
    import TextHighlight from 'vue-text-highlight'
    export default {
        name :'description',
        components: {TextHighlight},
        props : {
            queries : {
                type: String,
                default: null
            },
            value : {
                type: String
            }
        },
        computed : {
            useHighlight() {
                if (this.queries && this.queries.trim().length > 0) {
                    return true;
                }
                
                return false
            }
        },
        data : function() {
            return {
            }
        }
    }
</script>
<style lang="scss" scoped>
    .description-cmp {
        width: 100%;
    }
    .simple {
        display: block;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
    }
</style>