<template>
  <div>
    <h1>{{ breadcrumb() }}</h1>
    <model-list
        ref="list"
        @rowclick="model = $event"
        :route=true
        :routeParams="routeParams"
        :av="true"
        :params="gridParams"
    >
      <a href="#" v-if="hasRole('ROLE_CROP_MANAGER')" @click.prevent="add = true" class="button">
        dodaj
        <i class="ico ico-add"/>
      </a>
      <archive-button v-model="routeParams.archive" @input="refresh()"/>
    </model-list>
    <model-list
        v-if="add"
        :search="$refs.list.query.search"
        @rowclick="model = $event, model.enabled=true, add = false"
        @exit="add = false"
        :modal=true
        :route=false
        :av="false"
        :params="searchParams"
        exclude="own"
    >
      <template v-slot:header>
        <h2>Dodaj z listy</h2>
      </template>
      <template v-slot:footer>
        <a href="#" @click.stop="add = false" class="button">anuluj <i class="ico ico-close"></i></a>
      </template>
    </model-list>
    <model-form v-if="model" :model="model" @exit="model = false" @refresh="refresh()"/>
  </div>
</template>
<script>
import ModelForm from './form/Crop'
import ModelList from './list/Crop'
import Search from '../util/Search'
import { mapActions } from "vuex";

export default {
  components: { ModelForm, Search, ModelList },
  data() {
    return {
      model: false,
      add: false,
      search: null,
      gridParams: {
        own: true
      },
      searchParams: {
        import: true
      },
      routeParams: {
        archive: false
      }
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    breadcrumb() {
      return 'Rośliny uprawne';
    },
    refresh() {
      this.model = false
      this.$refs.list.load()
    }
  }
}
</script>
