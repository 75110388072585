<template>
  <span class="desc">
                <span v-if="position.mixture">
                  {{ position.mixture.name }}
                  <small v-if="position.mixture.description"
                         class="description fertilizer-description-move">
                    <label>Zastosowanie:</label>
                    {{ position.mixture.description }}
                  </small>
                  <div
                      v-if="position.mixture.fertilizers.length > 0 || position.mixture.plantProtections.length > 0"
                      class="grid positions"
                  >
                    <div class="head">
                      <div class="name">Nazwa środka</div>
                      <div class="quantity">Ilość na [ha]</div>
                      <div class="quantity quantity-value">Na opryskiwacz <strong><unit
                          :value="position.mixture.surface" :unit="false"/></strong>[ha]
                      </div>
                      <div class="content" v-if="editEnabled">Substancja czynna</div>
                    </div>
                    <div class="row"
                         v-for="(row, index) in [...position.mixture.plantProtections, ...position.mixture.fertilizers]"
                         :key="'pos'+index">
                      <div class="name">
                        <span>
                          {{ row.name }}
                        </span>
                      </div>
                      <div class="quantity">
                        <span class="simple-row num">
                          <span class="input-value">
                            <unit :unit="row.unit" :value="row.quantity"/>
                          </span>
                        </span>
                      </div>
                      <div class="quantity  quantity-value">
                        <span class="simple-row num">
                          <span>
                            <unit
                                :unit="row.unit"
                                :value="row.quantity * position.surface"/>
                          </span>
                        </span>
                      </div>
                      <div class="content" v-if="editEnabled">
                        <template v-if="row.composition">
                          <span v-if="row.composition.n">
                            <strong>N</strong>
                            <span class="input-value">
                              {{ $n(row.composition.n, 'simple') }}
                            </span>
                          </span>
                          <span v-if="row.composition.p">
                            <strong>P<sub>2</sub>O<sub>5</sub></strong>
                            <span class="input-value">
                              {{ $n(row.composition.p, 'simple') }}
                            </span>
                          </span>
                          <span v-if="row.composition.b">
                            <strong>K<sub>2</sub>O</strong>
                            <span class="input-value">
                              {{ $n(row.composition.b, 'simple') }}
                            </span>
                          </span>
                          <span v-if="row.composition.ca">
                            <strong>CaO</strong>
                            <span class="input-value">
                              {{ $n(row.composition.ca, 'simple') }}
                            </span>
                          </span>
                          <span v-if="row.composition.mg">
                            <strong>MgO</strong>
                            <span class="input-value">
                              {{ $n(row.composition.mg, 'simple') }}
                            </span>
                          </span>
                        </template>
                        <template v-if="row.contents">
                          <div class="composition">
                            <span v-for="(cnt, ii) in row.contents"
                                  v-bind:key="ii">
                              <strong>{{ cnt.ingredient.name }}</strong> - <unit
                                :value="cnt.quantity" :unit="cnt.unit"/>{{
                                ii < row.contents.length - 1 ? ', ' : ''
                              }}
                            </span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </span>
              </span>
</template>
<script>
import Unit from "@/components/widget/Unit.vue";

export default {
  components: {
    Unit
  },
  props: {
    position: {
      type: Object,
      required: true
    },
    editEnabled: {
      default: false
    },
  }
}
</script>
