<template>
  <modal class="modal-form" @exit="$emit('exit')">
    <template v-slot:header>
      <h2 v-if="position.stockId">
        Edycja rośliny <strong>{{ position.name || position.crop.name }}</strong>
      </h2>
      <h2 v-else>
        Nowa roślina <strong>{{ position.name || position.crop.name }}</strong>
      </h2>
    </template>
    <template v-slot:body>
      <form @submit.stop.prevent="save" class="form-table">
        <div class="field-row">
          <div class="form-group name">
            <label>Nazwa</label>
            <div class="form-text">
              <input-wrapper :error="errors.name">
                {{ position?.name || position.crop.name }}
              </input-wrapper>
            </div>
          </div>
          <div class="form-group groups">
            <label>Zastosowanie</label>
            <div class="form-text">
              <input-wrapper :error="errors.cropGroup">
                {{ position?.cropGroup?.name || position.crop.cropGroup.name }}
              </input-wrapper>
            </div>
          </div>
        </div>
        <confirm v-if="remove" @close="remove=false" @ok="onRemove">
          Z Twojej listy zostanie usunięta roślina <strong>{{ position.crop.name }}</strong>
        </confirm>
      </form>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left form-table">
          <div class="field-row">
            <div class="form-group simple-group">
              <label>Dostępność</label>
              <div class="form-control">
                <label>
                  <input type="checkbox" :disabled="!hasRole('ROLE_CROP_MANAGER')" name="check"
                         id="position-enabled" v-model="position.enabled"/>
                  włącz
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <button type="button" v-if="hasRole('ROLE_CROP_MANAGER')" @click="save" class="button">
            zapisz
            <i class="ico ico-save" />
          </button>
          <button type="button" @click.prevent="$emit('exit')" class="button">
            anuluj
          </button>
          <button type="button" v-if="hasRole('ROLE_CROP_MANAGER') && model && model.id"
                  @click.prevent="remove = true" class="button button-danger">
            usuń
            <i class="ico ico-remove" />
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import InputWrapper from '../../util/Input';
import Units from '@/service/unit.js'
import { mapActions } from "vuex";
import cropsApi from '@/api/crops'

export default {
  props: {
    model: null,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: { InputWrapper },
  data() {
    const tmp = {
      enabled: true,
    };
    return {
      position: { ...tmp, ...this.model },
      errors: {},
      remove: false,
      units: Units.getAll()
    }
  },
  mounted() {
    this.$bus.$emit('focus', this.$refs.first)
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    onRemove() {
      cropsApi.deleteCrop(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh');
        this.$emit('close');
      });
    },
    save() {
      if (!this.hasRole('ROLE_CROP_MANAGER')) {
        return
      }
      let promise;
      if (this.isEdit) {
        promise = cropsApi.updateCrop(this.model.id, { enabled: this.position.enabled })
      } else {
        promise = cropsApi.createCrop({ cropId: this.position.id, enabled: this.position.enabled })
      }
      promise.then(() => {
        this.$emit('refresh');
        this.errors = {};
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('saved', this.position);
        this.$emit('refresh');
        this.$dictionary.clear('/crop')
      }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors = this.$errors.transform(err.response.data)
          this.$notify({
            title: this.$t('error.form'),
            type: 'error'
          })

        } else {
          this.$notify({
            title: this.$t('error.connection'),
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style scoped>
input[type=text], input[type=number], select {
  width: 100%;
}

.quantity-group select, .quantity-group input {
  width: 100px;
}
</style>
