<template>
  <div>
    <h1>{{ breadcrumb() }}</h1>
    <plant-protection-list ref="list" @rowclick="model = $event" :route=true :routeParams="routeParams" :av="true"
                           :params="gridParams" >
      <a href="#" v-if="hasRole('ROLE_PLANT_PROTECTION_MANAGER')" @click.prevent="add = true" class="button">dodaj
        <i class="ico ico-add"></i></a>
      <archive-button v-model="routeParams.archive" @input="refresh()"/>
    </plant-protection-list>
    <plant-protection-list :search="$refs.list.query.search" v-if="add" @excludedrowclick="model = $event, add = false"
                           @rowclick="model = $event, model.enabled=true, add = false" @exit="add = false" :modal=true
                           :route=false :av="false" exclude="own">
      <template v-slot:header>
        <h2>Dodaj środek</h2>
      </template>
      <template v-slot:footer>
        <a href="#" @click.stop="add = false" class="button">anuluj <i class="ico ico-close" /></a>
      </template>
    </plant-protection-list>
    <model-form v-if="model" :model="model" @exit="model = false" @refresh="refresh()"/>
  </div>
</template>

<script>
import ModelForm from './form/PlantProtection'
import TextHighlight from 'vue-text-highlight'
import Search from '../util/Search'
import PlantProtectionList from './list/PlantProtection'
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ModelForm, TextHighlight, Search, PlantProtectionList },
  data() {
    return {
      model: false,
      add: false,
      gridParams: {},
      routeParams: {
        archive: false
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  created() {
    this.gridParams.clientId = this.user.id
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    breadcrumb() {
      return 'Moje środki ochrony roślin';
    },
    refresh() {
      this.model = false
      this.$refs.list.load()
    }
  }
}
</script>

<style scoped>

</style>
