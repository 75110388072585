<template>
  <modal class="modal-form" @exit="$emit('exit')">
    <template v-slot:header>
      <h2 v-if="position.id">Edycja etykiety <strong>{{ position.name }}</strong></h2>
      <h2 v-else>Nowa etykieta <strong>{{ position.name }}</strong></h2>
    </template>
    <template v-slot:body>
      <form @submit.stop.prevent="save" class="form-table">
        <div class="field-row">
          <div class="form-group crop">
            <label>Roślina</label>
            <div class="form-text">
              <input-wrapper :error="errors.crop">
                <v-select append-to-body :options="dictionaries['/crop']" :disabled="!editEnabled"
                          v-model="position.crop" label="name" ref="first"></v-select>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group name">
            <label>Nazwa</label>
            <div class="form-text">
              <input-wrapper :error="errors.name">
                <input type="text" class="text" :disabled="!editEnabled" v-model="position.name"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group norm">
            <label>Norma</label>
            <div class="form-text">
              <input-wrapper :error="errors.norm">
                <input type="number" lang="pl" step=".01" class="text" :disabled="!editEnabled"
                       v-model="position.norm"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group quantity-group">
            <label>Stan</label>
            <div class="form-control">
              <div class="simple-row">
                <input-wrapper :error="errors.stock">
                  <field-unit unit="kg" class="text" :disabled="!editEnabled" v-model="position.stock"/>
                </input-wrapper>
                <span class="input-value">
                                    <unit unit="kg" :value="false"/>
                                </span>
              </div>
            </div>
          </div>
          <div class="form-group groups">
            <label>Opis</label>
            <div class="form-text">
              <input-wrapper :error="errors.description">
                <textarea-autosize v-model="position.description" :disabled="!editEnabled"></textarea-autosize>
              </input-wrapper>
            </div>
          </div>
        </div>
      </form>
      <confirm v-if="remove" @close="remove=false" @ok="onRemove">
        Zostanie usunięta etykieta <strong>{{ position.name }}</strong>
      </confirm>
      <modal v-if="norm" @keyup.escape="norm = false" class="">
        <template v-slot:header>
          Oblicz normę
        </template>
        <template v-slot:body>
          <form @submit.prevent="norm = false; position.norm = calculator.result" class="modal-form">
            <span class="form-label">Obsada</span>
            <span class="row">
                            <span class="form-input"><input type="number" lang="pl" v-model="calculator.cast"/></span>
                            <span class="form-addon">[szt./ m<sup>2</sup>]</span>
                        </span>
            <span class="form-label">MTZ</span>
            <span class="row">
                            <span class="form-input"><input type="number" lang="pl" v-model="calculator.mtz"/></span>
                            <span class="form-addon"> [g]</span>
                        </span>
            <span class="form-label">Siła kiełkowania</span>
            <span class="row">
                            <span class="form-input"><input type="number" lang="pl" v-model="calculator.force"/></span>
                            <span class="form-addon">[%]</span>
                        </span>
            <div class="line"></div>
            <span class="form-label">Wynik</span>
            <span class="row">
                            <span class="form-input"><input type="number" lang="pl" v-model="calculator.result"
                                                            readonly="readonly"/></span>
                            <span class="form-addon"> [kg/ha]</span>
                        </span>
          </form>
        </template>
        <template v-slot:footer>
          <button v-if="editEnabled" type="button" class="button" @click.prevent="saveCalc()">ok</button>
          <button type="button" class="button" @click.prevent="norm = false">anuluj</button>
        </template>
      </modal>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left form-table">
          <div class="field-row">
            <div class="form-group simple-group">
              <label>Dostępność</label>
              <div class="form-control">
                <label><input type="checkbox" :disabled="!editEnabled" name="check" id="position-enabled"
                              v-model="position.enabled"/> włącz</label>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <button v-if="editEnabled" type="submit" class="button" @click="save">zapisz <i class="ico ico-save"></i>
          </button>
          <button type="button" @click.prevent="$emit('exit')" class="button">anuluj</button>
          <button type="button" @click.prevent="norm=true" class="button">oblicz normę</button>
          <button type="button" v-if="editEnabled && model" @click.prevent="remove = true" class="button button-danger">
            usuń <i class="ico ico-remove"></i></button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import InputWrapper from '../../util/Input';
import Confirm from '../../util/Confirm';
import Modal from '../../util/Modal';
import Unit from '@/components/widget/Unit'
import FieldUnit from '@/components/widget/FieldUnit'
import { mapActions } from "vuex";
import labelsApi from '@/api/labels'
import cropsApi from '@/api/crops'

export default {
  props: { model: null },
  components: { InputWrapper, Confirm, Modal, FieldUnit, Unit },
  data() {
    const tmp = {
      name: '',
      enabled: true,
      description: '',
      norm: null,
      crop_id: null,
      stock: 0
    };
    return {
      position: { ...tmp, ...this.model },
      errors: {},
      remove: false,
      dictionaries: { "/crop": [] },
      editEnabled: this.hasRole('ROLE_LABEL_MANAGER'),
      norm: false,
      calculator: {
        cast: null,
        mtz: null,
        force: null,
        result: null
      }
    }
  },
  watch: {
    calculator: {
      deep: true,
      handler() {
        this.calc();
      }
    }
  },
  created() {
    if (this.editEnabled) {
      cropsApi.getCrops().then((response) => {
        this.dictionaries['/crop'] = response.data.collection.map((item) => {
          return { id: item.id, name: item.crop.name }
        });
      })
    }
  },
  mounted() {
    this.$bus.$emit('focus', this.$refs.first);
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    onRemove() {
      labelsApi.deleteLabel(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh');
        this.$emit('exit');
      });
    },
    save() {
      if (!this.editEnabled) {
        return false;
      }
      let promise = null;
      this.position.cropId = this.position.crop?.id;
      if (this.model && this.model.id) {
        promise = labelsApi.updateLabel(this.model.id, this.position);
      } else {
        promise = labelsApi.createLabel(this.position);
      }

      promise.then(() => {
        this.$emit('refresh');
        this.errors = {};
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        if (this.model) {
          this.$emit('saved', this.position);
        } else {
          this.$emit('created', this.position);
        }
      }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors = this.$errors.transform(err.response.data)
          this.$notify({
            title: this.$t('error.form'),
            type: 'error'
          })

        } else {
          this.$notify({
            title: this.$t('error.connection'),
            type: 'error'
          })
        }
      })
    },
    calc() {
      const cast = this.calculator.cast;
      const mtz = this.calculator.mtz;
      const germinationForce = this.calculator.force;

      const numberOfSownSeeds = (cast * mtz) / germinationForce;


      if (cast !== "" && cast !== 0 && mtz !== "" && mtz !== 0 && germinationForce !== "" && germinationForce !== 0)
        this.calculator.result = numberOfSownSeeds.toFixed(2);
      else
        this.calculator.result = 0;

    },
    saveCalc() {
      this.norm = false;

      if (this.editEnabled) {
        this.position.norm = this.calculator.result
      }
    }
  },
}
</script>

<style scoped lang="scss">
input[type=text], input[type=number], select {
  width: 100%;
}

.norm input {
  width: 80px;
}

form {
  color: black;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

.line {
  margin-bottom: 10px;
}

.row {
  background: transparent;
  border: 0 solid;
  padding-bottom: 10px;

  .form-addon {
    width: 60px;
    font-size: 14px;
  }

  .form-input {
    padding-right: 10px;
    flex: 1;
  }
}
</style>
