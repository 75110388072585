<template>
   <div class="login">
    <loader color="#0065a2" class="loader" />

   </div>
</template>
 
<script>
   import { EventBus } from './../service/bus.js'
   import loader from './util/Loader'
   export default {
    props: ['token'],
    components: {loader},
    mounted() {
        //this.$auth.watch.authenticated = true
        //this.$auth.watch.loaded = true      
        //this.$auth.watch.data = {}
        this.$http.get('auth/user', {
            headers: {
                Authorization: 'Bearer ' + this.token
            }
        }).then(resp => {
            this.$auth.user(resp.data.data)
            this.$auth.token(null, this.token)
            this.$auth.watch.authenticated = true

            this.$nextTick(() => {
                this.$router.replace({name: 'home'})
            })
        }).catch(() => {
            this.$router.replace({name: 'login'})
        })
    },
    methods : {
        breadcrumb: function() {
            return this.$t('login.title');
        }
    }
   }
</script>
 