<template>
  <div>
    <h1>{{ breadcrumb() }}</h1>
    <div class="edit-table">
      <div class="btn-bar">
        <div class="left">
          <a v-if="hasRole('ROLE_TASK_MANAGER') && !own" href="#" @click.prevent="create = true" class="button">dodaj
            nowe <i class="ico ico-add"></i></a>
          <archive-button v-model="archive" show="pokaż archiwalne" hide="ukryj archiwalne"/>
          <archive-button v-if="user.employer && hasRole('ROLE_TASK_MANAGER')" v-model="own"
                          show="tryb obsługi" hide="tryb edycji"/>
          <ul class="legend">
            <template v-for="status in statusList">
              <li :key="'status' + status.id" :class="status.name  + (statuses.includes(status.id) ? ' active' : '')"
                  v-if="archive || status.id !== 256" @click.prevent="selectStatus(status.id)">
                <i v-tooltip="status.title"></i>
              </li>
            </template>
          </ul>
          <span class="dates">
                        <datepicker valueType="YYYY-MM-DD" v-model="dateFrom" :clearable="true" placeholder="Od"
                                    type="date" format="DD.MM.YYYY"/>
                        -
                        <datepicker valueType="YYYY-MM-DD" v-model="dateTo" :clearable="true" placeholder="Do"
                                    type="date" format="DD.MM.YYYY"/>
                    </span>
        </div>
        <div class="right">
          <search v-model="search" @filter="filter()" :print="true"/>
        </div>
      </div>
      <div class="line"></div>
      <model-form v-if="create" :edit="!own" @refresh="load()" :model="create" @exit="create = false"
                  @created="create = false"/>
      <div class="grid">
        <div class="multi-head">
          <div class="head">
            <div class="date">Zlecono</div>
            <div class="field">Pole</div>
            <div class="description">Komentarz</div>
            <div class="date" v-if="archive">Zakończono</div>
          </div>
          <div class="head">
            <div class="task">Zadanie</div>
            <div class="quantity" v-tooltip="'Zlecona powierzchnia'">zlec. ha</div>
            <div class="crop">Roślina</div>
            <div class="label">Etykieta</div>
            <div class="protection">Mieszanina</div>
            <div class="norm">Norma</div>
            <div class="machine">Pojazd</div>
            <div class="machine">Maszyna</div>
            <div class="employer">Pracownik</div>
            <div class="quantity" v-tooltip="'Wykonane powierzchnia'">wyk. ha</div>
          </div>
        </div>
        <template v-if="grid.collection.length > 0">
          <template v-for="(position, i) in grid.collection">
            <div :class="'row multi-row '  + (position.status === 256 ? ' disabled' : '') + ' status-' + position.status"
                 :key="i" @click.prevent="create = position">
              <div class="sub-row">
                <div class="date">{{ formatDate(position.createdAt) }}</div>
                <div class="field">
                  <text-highlight :queries="highlight">{{ position.field ? position.field.name : '' }}</text-highlight>
                </div>
                <div class="description">
                  <description v-if="position.comments" :queries="highlight" :value="position.comments"/>
                  <description v-if="position.employerComments" :queries="''" :value="position.employerComments"/>
                </div>

                <div class="date btn-cell" v-if="archive">
                  {{ formatDate(position.closedAt) }}
                  <a href="#"><i class="icon-edit"></i></a>
                </div>
              </div>
              <div class="sub-row">
                <div class="task">
                  <text-highlight :queries="highlight">
                    {{ position.taskDefinition ? position.taskDefinition.name : '' }}
                  </text-highlight>
                </div>
                <div class="quantity num">
                  <span v-if="position.scheduledCultivatedArea !== null">
                    {{$n(position.scheduledCultivatedArea, 'simple') }} ha
                  </span>
                  <span v-else>-</span>
                </div>
                <div class="crop">
                  <text-highlight :queries="highlight">
                    {{ position.crop ? position.crop.name : '-' }}
                  </text-highlight>
                </div>
                <div class="label">
                  <text-highlight :queries="highlight">
                    {{ position.label ? position.label.name : '-' }}
                  </text-highlight>
                </div>
                <div class="protection">
                  <text-highlight :queries="highlight">
                    {{ position.fertilizer ? position.fertilizer.name : '' }}
                    {{ position.plantProtection ? position.plantProtection.name : '' }}
                    {{ position.mixture ? position.mixture.name : '' }}
                  </text-highlight>
                </div>
                <div class="norm">
                  <template v-if="position.fertilizerQuantity">
                    {{$n(position.fertilizerQuantity, 'simple') + (position.fertilizer ? position.fertilizer.unit : 'l') + '/ha' }}
                  </template>
                  <template v-else-if="position.labelNorm">
                    {{ $n(position.labelNorm, 'simple') + ' kg/ha' }}
                  </template>
                </div>
                <div class="machine">
                  <text-highlight :queries="highlight">
                    {{ position.vehicle ? position.vehicle.name : '-' }}
                  </text-highlight>
                </div>
                <div class="machine">
                  <text-highlight :queries="highlight">
                    {{position.machine ? position.machine.name + ' - ' + $n(position.machineWidth, 'simple') + ' m' : '' }}
                  </text-highlight>
                </div>
                <div :class="'employer'">
                  <text-highlight :queries="highlight">
                    {{ position.employer ? position.employer.name : null }}
                  </text-highlight>
                </div>
                <div :class="'quantity num ' +  (archive ? '' : 'btn-cell')">
                  <span v-if="position.realizedCultivatedArea !== null">
                    {{ $n(position.realizedCultivatedArea, 'simple') }} ha
                  </span>
                  <span v-else>-</span>
                  <a href="#" v-if="!archive"><i class="icon-edit"></i></a>
                </div>
              </div>
            </div>
          </template>
        </template>
        <div class="row" v-else>
          <div class="empty" colspan="12">
            <span v-if="$route.query.search">
              Nic nie znaleziono.
              <button type="button"
                      class="button"
                      @click.prevent="clearFilter()"
              >
                usuń filtr
              </button>
            </span>
            <span v-else>Lista jest pusta</span>
          </div>
        </div>
      </div>
      <paginator :pages="grid.pagination.pageCount" :active="grid.pagination.page" @click="onPage"/>
    </div>
  </div>
</template>

<script>
import ModelForm from './form/Task'
import TextHighlight from 'vue-text-highlight'
import Paginator from '../util/Paginator'
import Search from '../util/Search'
import moment from 'moment'
import ordersApi from '@/api/orders'
import { mapActions, mapGetters } from "vuex"

export default {
  components: { ModelForm, TextHighlight, Paginator, Search },
  data() {
    return {
      create: false,
      own: false,
      search: this.$route.query.search,
      archive: this.$route.query.archive ? (this.$route.query.archive !== 'false') : false,
      dateFrom: this.$route.query.dateFrom,
      dateTo: this.$route.query.dateTo,
      dictionaries: {
        crops: [],
        labels: [],
        fields: [],
        machines: [],
        vehicles: [],
        fertilizers: [],
        plantProtections: [],
        employers: [],
        taskDefinitions: []
      },
      statusList: [{
        id: 256,
        name: 'archive',
        title: 'archiwalne'
      }, {
        id: 0,
        name: 'empty',
        title: 'niepotwierdzone'
      }, {
        id: 2,
        name: 'own',
        title: 'przyjęte'
      }, {
        id: 8,
        name: 'progress',
        title: 'w toku'
      }],
      statuses: [],
      grid: {
        collection: [],
        pagination: {
          itemsLength: 0,
          page: 1,
          pageCount: 1,
        },
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    highlight() {
      if (typeof this.search != 'string') {
        return '';
      }
      return this.search;
    }
  },
  watch: {
    $route() {
      this.search = this.$route.query.search;
      if (this.$route.query.dateFrom) {
        this.dateFrom = this.$route.query.dateFrom
      }
      if (this.$route.query.dateTo) {
        this.dateTo = this.$route.query.dateTo
      }
      this.load();
    },
    archive() {
      this.updateQuery({
        page: 1,
        archive: this.archive ? 'true' : 'false'
      })
    },
    own() {
      this.load();
    },
    dateFrom() {
      if (this.dateFrom) {
        this.archive = true
      }
      this.filter()
    },
    dateTo() {
      if (this.dateTo) {
        this.archive = true
      }
      this.filter()
    }
  },
  created() {
    this.load()
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    formatDate(date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY');
      }
      return '';
    },
    clearFilter() {
      this.search = null;
      this.filter();
    },
    onPage(page) {
      this.updateQuery({
        page: page
      })
    },

    filter() {
      this.updateQuery({
        search: this.search,
        dateFrom: this.dateFrom ? moment(this.dateFrom).format('YYYY-MM-DD') : null,
        dateTo: this.dateTo ? moment(this.dateTo).format('YYYY-MM-DD') : null,
        page: 1
      })
    },
    updateQuery(params) {
      let query = Object.assign({}, this.$route.query, params);
      if (query.page == 1) {
        delete query.page;
      }
      if (!query.search) {
        delete query.search;
      }
      if (!query.dateTo) {
        delete query.dateTo;
      }
      if (!query.dateFrom) {
        delete query.dateFrom;
      }
      this.$router.push({ query: query }).catch(() => {
        this.load();
      });
    },
    load() {
      if (!this.archive && this.statuses.length === 1 && this.statuses[0] === 256) {
        this.statuses = [];
      }
      ordersApi.getOrders({
        search: this.$route.query.search,
        page: this.$route.query.page || 1,
        archive: this.$route.query.archive ? this.$route.query.archive : 'false',
        dateFrom: this.$route.query.dateFrom || null,
        dateTo: this.$route.query.dateTo || null,
        own: this.own ? 'true' : 'false',
        status: this.statuses
      }).then((resp) => {
        resp.data.collection.forEach(function (item) {
          item.edit = false;
        })
        this.grid = resp.data;

      });
    },
    breadcrumb() {
      return 'Zlecenia';
    },
    saved(i, old, item) {
      old.edit = false;
      this.grid.collection[i] = { ...item };
      this.grid.collection[i].edit = false;
    },
    selectStatus(id) {
      if (this.statuses.includes(id)) {
        this.statuses = this.statuses.filter((st) => {
          return st !== id
        })
      } else {
        this.statuses.push(id)
      }
      this.load()
    }
  },
}
</script>

<style scoped lang="scss">
div.date {
  width: 100px;
}

div.task {
  width: 100px;
}

div.field {
  width: 403px;
}

div.quantity {
  width: 70px;
}

div.norm {
  width: 70px;
}

div.machine, div.vehicle {
  width: 100px;
}

div.crop, div.label {
  width: 100px;
}

div.protection {
  width: 100px;
}

div.employer {
  width: 150px;
}

.row.status-2, .row.status-2:hover {
  color: black;
  background: #abffb3 !important;
  border: 1px solid #62c36b !important;

  > div, > .sub-row > div {
    border-color: #62c36b !important;
  }
}

.row.status-8, .row.status-8:hover {
  color: black;
  background: #feffab !important;
  border: 1px solid #b9c362 !important;

  > div, > .sub-row > div {
    border-color: #b9c362 !important;
  }
}

.legend {
  display: inline-block;
  padding-left: 10px;

  li {
    display: inline-block;
    font-weight: normal !important;
    font-style: normal !important;
    line-height: 25px;
    cursor: pointer;
    color: #444;
    padding: 0 4px;

    i {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #d1d1d1;
      border-radius: 50px;
      background: white;
      line-height: 20px;
      position: relative;
      top: -1px;
      vertical-align: middle;
    }

    &:hover {
      color: black;
    }

    &.archive i {
      background: #d1d1d1;
      border-color: #d1d1d1 !important;
    }

    &.own i {
      background: #62c36b;
      border-color: #62c36b !important;
    }

    &.progress i {
      background: #feffab;
      border-color: #b9c362 !important;
    }

    &.active {
      span {
        color: black;
        border-bottom: 1px solid black;
      }

      i {
        border: 2px solid #444 !important;
        width: 18px;
        line-height: 19px;
        height: 19px;
      }
    }
  }
}

.dates {
  display: inline-flex;
  padding-left: 10px;

  > div {
    width: 150px;
  }
}

.button, .legend, .dates {
  margin-bottom: 5px;
}

@import '~/css/vars.scss';
@media screen and (max-width: $small) {
  .grid .row.disabled > div, .grid .row.disabled > .sub-row > div, .grid .row.disabled:hover > div, .grid .row.disabled:hover > .sub-row > div {
    font-size: 12px;
  }
  div.employer {
    width: 120px;
  }
  div.machine {
    width: 90px;
  }
}

@media screen and (max-width: $tablet1) {
  div.quantity {
    display: none;
  }
}

@media screen and (max-width: $tablet2) {
  div.machine {
    display: none;
  }
}

@media screen and (max-width: $phone1) {
  div.protection, div.employer {
    display: none;
  }
}

@media screen and (max-width: $phone2) {
  div.protection, div.crop, div.description {
    display: none;
  }
  div.field {
    flex: 1 !important;
    border-right: 0 solid;
  }
}
</style>
