<template>
  <form @submit.stop.prevent="save" class="form-table">
    <table>
      <thead>
      <tr>
        <th style="width: 650px;">Nazwa</th>
        <th style="width: 50px;">Udostępnienie</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <input-wrapper :error="errors.name">
            <input type="text" class="text" v-model="position.name" :disabled="!editEnabled" ref="first"/>
          </input-wrapper>
        </td>

        <td><label><input type="checkbox" name="check" v-model="position.enabled" :disabled="!editEnabled"/>
          włącz</label></td>
      </tr>
      </tbody>
    </table>
    <div class="btn-bar">
      <div class="left"></div>
      <div class="right">
        <button v-if="editEnabled" type="submit" class="button">
          zapisz
          <i class="ico ico-save"></i>
        </button>
        <button type="button" @click.prevent="$emit('exit')" class="button">
          anuluj
        </button>
        <button
            type="button"
            v-if="editEnabled && model && model.id"
            @click.prevent="remove = true"
            class="button button-danger">
          usuń
          <i class="ico ico-remove"></i>
        </button>
      </div>
    </div>
    <confirm v-if="remove" @close="remove=false" @ok="onRemove">
      Zostanie usunięty sezon
      <strong>{{ position.name }}</strong>
    </confirm>
  </form>

</template>

<script>
import InputWrapper from '../../util/Input';
import Confirm from '../../util/Confirm';
import { mapActions } from "vuex";
import seasonsApi from '@/api/seasons'

export default {
  props: { model: null },
  components: { InputWrapper },
  mounted() {
    this.$bus.$emit('focus', this.$refs.first);
  },
  data() {
    const tmp = this.model || {
      name: '',
      enabled: true,
    };
    return {
      position: { ...tmp },
      errors: {},
      remove: false,
      editEnabled: this.hasRole('ROLE_PLANT_SEASON_MANAGER')
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    breadcrumb() {
      return 'Zlecenia';
    },
    onRemove() {
      seasonsApi.deleteSeason(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh');
        this.$emit('exit');
      });
    },
    save() {
      if (!this.editEnabled) {
        return;
      }
      let promise = null;
      if (this.model && this.model.id) {
        promise = seasonsApi.updateSeason(this.model.id, this.position);
      } else {
        promise = seasonsApi.createSeason(this.position);
      }

      promise.then(() => {
        this.$emit('refresh');
        this.errors = {};
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        if (this.model) {
          this.$emit('saved', this.position);
        } else {
          this.$emit('created', this.position);
        }
      }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors = this.$errors.transform(err.response.data)
          this.$notify({
            title: this.$t('error.form'),
            type: 'error'
          })

        } else {
          this.$notify({
            title: this.$t('error.connection'),
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style scoped>
input[type=text], input[type=number], select {
  width: 100%;
}
</style>
