<template>
  <modal class="modal-form" @exit="$emit('exit')">
    <template v-slot:header>
      <span v-if="position.id">
        Edycja pracownika
        <strong>
          {{ position.firstname }}
          {{position.lastname }}
        </strong>
      </span>
      <span v-else>
        Nowy pracownik
        <strong>{{ position.firstname }} {{ position.lastname }}</strong>
      </span>
    </template>
    <template v-slot:body>
      <form @submit.stop.prevent="save" class="form-table" autocomplete="none">
        <div class="field-row">
          <div class="form-group first-name">
            <label>Imię</label>
            <div class="form-control">
              <input-wrapper :error="errors.firstname">
                <input type="text" class="text" :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')"
                       v-model="position.firstname" ref="first"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group last-name">
            <label>Nazwisko</label>
            <div class="form-control">
              <input-wrapper :error="errors.lastname">
                <input type="text" class="text" :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')"
                       v-model="position.lastname"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group position">
            <label>Stanowisko</label>
            <div class="form-control">
              <input-wrapper :error="errors.position">
                <input type="text" class="text" :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')"
                       v-model="position.position"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group sign">
            <label>Identyfikator</label>
            <div class="form-control">
              <input-wrapper :error="errors.sign">
                <input type="text" class="text" :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')"
                       v-model="position.sign"/>
              </input-wrapper>
            </div>
          </div>

          <div class="form-group description">
            <label>Opis</label>
            <div class="form-control">
              <textarea-autosize
                  v-model="position.description"
                  :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')"
              />
            </div>
          </div>

        </div>
        <div class="field-row">
          <div class="form-group user">
            <label>Użytkownik</label>
            <div class="form-control">
              <label>
                <input
                    type="checkbox"
                    v-model="hasUser"
                    :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')"
                    ref="user"
                />
                utwórz konto pracownika
              </label>
            </div>
          </div>
          <div class="form-group username" v-if="position.user">
            <label>Login</label>
            <div class="form-control">
              <input-wrapper :error="errors['user.usernameCanonical']">
                <input
                    type="text"
                    class="text"
                    :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')"
                    v-model="position.user.username" autocomplete="off"
                />
              </input-wrapper>
            </div>
          </div>
          <div class="form-group pass" v-if="position.user">
            <label>Nowe hasło</label>
            <div class="form-control">
              <input-wrapper :error="errors['user.password']">
                <input
                    type="password" class="text"
                    :placeholder="model.userId ? 'wypełnij żeby zmienić hasło' : 'wypełnij aby ustawić hasło'"
                    :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')"
                    v-model="position.user.password"
                    autocomplete="new-password"
                />
              </input-wrapper>
            </div>
          </div>
          <div class="form-group email" v-if="position.user">
            <label>E-mail</label>
            <div class="form-control">
              <input-wrapper :error="errors['user.email']">
                <input type="text" class="text" :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')"
                       v-model="position.user.email" autocomplete="off"/>
              </input-wrapper>
            </div>
          </div>

          <div class="form-group privileges" v-if="position.user">
            <label>Uprawnienia</label>
            <div class="form-control">
              <input-wrapper :error="errors['user.roles']">
                <template v-for="role in privileges">
                  <div :key="role.id" class="role-row">
                    <label class="inline">
                      <strong>{{ role.name }}</strong>
                    </label>
                    <label class="inline role">
                      <input type="checkbox" :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')" :value="role.id"
                             :checked="hasRole(role.id)" @change="setRole(role.id, $event)"/> dostęp
                    </label>
                    <label class="inline role">
                      <input type="checkbox" :disabled="!userHasRole('ROLE_EMPLOYER_MANAGER')"
                             :value="role.id + '_MANAGER'" :checked="hasRole(role.id + '_MANAGER')"
                             @change="setRole(role.id + '_MANAGER', $event)"/> zarządzanie
                    </label>
                  </div>
                </template>
              </input-wrapper>
            </div>
          </div>
        </div>
        <confirm v-if="remove" @close="remove=false" @ok="onRemove">
          Zostanie usunięty pracownik <strong>{{ position.firstname }} {{ position.lastname }}</strong>
        </confirm>
      </form>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left form-table">
          <div class="field-row">
            <div class="form-group simple-group">
              <label>Dostępność na liście</label>
              <div class="form-control">
                <label><input type="checkbox" :disabled="!userHasRole('ROLE_FERTILIZER_MANAGER')" name="check"
                              id="position-enabled" v-model="position.enabled"/> włącz</label>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <button type="button" v-if="userHasRole('ROLE_EMPLOYER_MANAGER')" @click="save" class="button">zapisz <i
              class="ico ico-save"></i></button>
          <button type="button" @click.prevent="$emit('exit')" class="button">anuluj</button>
          <button type="button" v-if="userHasRole('ROLE_EMPLOYER_MANAGER') && model && model.id"
                  @click.prevent="remove = true" class="button button-danger">usuń <i class="ico ico-remove"></i>
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import InputWrapper from '../../util/Input';
import { mapActions } from "vuex";
import employeesApi from "@/api/employees";

export default {
  props: { model: null },
  components: { InputWrapper },
  mounted() {
    this.$bus.$emit('focus', this.$refs.first);
  },
  data() {
    const tmp = {
      name: '',
      enabled: true,
      description: '',
      max: 1,
      user: null,
      stock: 0,
      ...this.model
    };
    return {
      position: { ...tmp },
      errors: {},
      remove: false,
      hasUser: tmp.user !== null,
      privileges: [
        {
          id: 'ROLE_FIELD',
          name: 'Pola uprawne'
        },
        {
          id: 'ROLE_TASK',
          name: 'Zlecenia'
        },
        {
          id: 'ROLE_CROP',
          name: 'Rośliny uprawne'
        },
        {
          id: 'ROLE_LABEL',
          name: 'Etykiety'
        },
        {
          id: 'ROLE_MIXTURE',
          name: 'Mieszaniny'
        },
        {
          id: 'ROLE_FERTILIZER',
          name: 'Nawozy'
        },
        {
          id: 'ROLE_PLANT_PROTECTION',
          name: 'Środki ochrony'
        },
        {
          id: 'ROLE_PLANT_SEASON',
          name: 'Sezony'
        },
        {
          id: 'ROLE_TASK_DEFINITION',
          name: 'Zadania'
        },
        {
          id: 'ROLE_MACHINE',
          name: 'Maszyny'
        },
        {
          id: 'ROLE_VEHICLE',
          name: 'Pojazdy'
        },
        {
          id: 'ROLE_EMPLOYER',
          name: 'Pracownicy'
        },


      ],
    }
  },
  methods: {
    ...mapActions('auth', { userHasRole: 'hasRole' }),

    buildOption(opt) {
      return {
        id: opt,
        name: opt
      }
    },
    onRemove() {
      employeesApi.deleteEmployee(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh');
        this.$emit('close');
      });
    },
    save() {
      if (!this.userHasRole('ROLE_EMPLOYER_MANAGER')) {
        return
      }
      let promise = null;
      if (this.model && this.model.id) {
        promise = employeesApi.updateEmployee(this.model.id, this.position);
      } else {
        promise = employeesApi.createEmployee(this.position);
      }

      promise.then(() => {
        this.$emit('refresh');
        this.errors = {};
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        if (this.model) {
          this.$emit('saved', this.position);
        } else {
          this.$emit('created', this.position);
        }
      }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors = this.$errors.transform(err.response.data)
          this.$notify({
            title: this.$t('error.form'),
            type: 'error'
          })

        } else {
          this.$notify({
            title: this.$t('error.connection'),
            type: 'error'
          })
        }
      })
    },
    hasRole(role) {
      if (!this.position.user) {
        return false
      }

      return this.position.user.roles.includes(role)
    },
    setRole(role, value) {
      if (value.target.checked) {
        this.position.user.roles.push(role)
        if (role.indexOf('_MANAGER') > 0) {
          let main = role.substring(0, role.length - 8)
          if (!this.position.user.roles.includes(main)) {
            this.position.user.roles.push(main)
          }
        }
      } else {
        this.position.user.roles = this.position.user.roles.filter(i => i !== role)
        if (role.indexOf('_MANAGER') === -1) {
          this.setRole(role + '_MANAGER', value)
        }
      }

    }
  },
  watch: {
    position: {
      deep: true,
      handler(m) {
        this.hasUser = m.user !== null
      }
    },
    hasUser(v) {
      if (v) {
        this.position.user = {
          password: null,
          username: null,
          email: null,
          roles: ['ROLE_TASK']
        }
      } else {
        this.position.user = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-table {
  display: flex;

  > .field-row {
    display: block;
    width: 50%;

    > div {
      width: 100%;
    }
  }
}

input[type=text], input[type=number], input[type=password], select {
  width: 100%;
}

.privileges {
  width: 100% !important;
}

.description {
  flex-grow: 1;
}

.inline {
  display: inline-block !important;
  padding: 2px !important;
  width: 150px !important;
  line-height: 1.2em
}

.role {
  width: 100px !important;
}

.role-row:hover {
  background: #dfdfdf;
}
</style>
