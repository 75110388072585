import axiosApi from "@/api/axios";

export default {
    getCrops(params) {
        return axiosApi.get("/client_crops", {
        params,
        });
    },
    createCrop(params) {
        return axiosApi.post("/client_crops", params);
    },
    updateCrop(id, params) {
        return axiosApi.put(`/client_crops/${id}`, params);
    },
    deleteCrop(id) {
        return axiosApi.delete(`/client_crops/${id}`);
    },
    getAvailableCrops(params) {
        return axiosApi.get("/crops", {
        params,
        });
    }
}
