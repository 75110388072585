import humps from 'humps';

export default {
    transform(response) {
        let errors = {}

        if (response.error.details) {
            response.error.details.forEach(function (err) {
                const error = err
                // if err.key has _id in it then strip it
                error.key = error.key.replace('_id', '');
                errors[err.key] = err.message;
            })
        }
        return humps.camelizeKeys(errors);
    }
}
