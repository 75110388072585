<template>
  <div>
    <h1 class="title">Pulpit</h1>
    <div class="page">
      <ul class="menu">
        <li v-if="hasRole('ROLE_HOME')">
          <router-link :to="{name : 'home'}"><i class="home"></i> Gospodarstwo</router-link>
        </li>
        <li v-if="hasRole('ROLE_TASK')">
          <router-link :to="{name : 'orders'}"><i class="orders"></i>Zlecenia</router-link>
        </li>
        <li v-if="hasRole('ROLE_FIELD')">
          <router-link :to="{name : 'fields'}"><i class="fields"></i>Pola uprawne</router-link>
        </li>
        <li v-if="hasRole('ROLE_PLANT_PROTECTION')">
          <router-link :to="{name : 'plantProtections'}"><i class="plantProtections"></i>Środki ochrony</router-link>
        </li>
        <li v-if="hasRole('ROLE_FERTILIZER')">
          <router-link :to="{name : 'fertilizers'}"><i class="fertilizers"></i>Nawozy</router-link>
        </li>
        <li v-if="hasRole('ROLE_CROP')">
          <router-link :to="{name : 'crops'}"><i class="crops"></i> Rośliny uprawne</router-link>
        </li>
        <li v-if="hasRole('ROLE_LABEL')">
          <router-link :to="{name : 'labels'}"><i class="labels"></i>Etykiety</router-link>
        </li>
        <li v-if="hasRole('ROLE_VEHICLE')">
          <router-link :to="{name : 'vehicles'}"><i class="vehicles"></i>Pojazdy</router-link>
        </li>
        <li v-if="hasRole('ROLE_MACHINE')">
          <router-link :to="{name : 'machines'}"><i class="machines"></i>Maszyny</router-link>
        </li>
        <li v-if="hasRole('ROLE_TASK_DEFINITION')">
          <router-link :to="{name : 'taskDefinitions'}"><i class="tasks"></i>Zadania</router-link>
        </li>
        <li v-if="hasRole('ROLE_EMPLOYER')">
          <router-link :to="{name : 'employers'}"><i class="employers"></i>Pracownicy</router-link>
        </li>
        <li v-if="hasRole('ROLE_ARCHIVE')"><a href="#"><i class="archives"></i>Archiwum</a></li>

      </ul>
    </div>
  </div>
</template>

<script>
import loader from './util/Loader';
import sitemenu from './util/Menu'
import { mapActions, mapGetters } from "vuex";

export default {
  title: 'Pulpit',
  components: { loader, sitemenu },
  data() {
    return {
      loadingData: true,
      data: null,
      settlementData: {
        success: false,
        summary: {
          saldo: 0,
          oldSaldo: 0
        }
      },
      loadingOrders: true,
      loadingSettlements: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    breadcrumb() {
      return 'Pulpit';
    },
  }
}
</script>
<style>
div.box > h2 {
  border-top: 5px solid #005691;
  text-transform: uppercase;
  color: #005691;
  font-size: 16px;
  padding: 0px 19px;
  line-height: 47px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
}

div.box > h2 i, div.box > h2 .right {
  position: absolute;
  top: 0px;
  right: 19px;
  height: 47px;
}
</style>
<style lang="scss">
.page .menu {
  display: block;
  text-align: center;
  margin: 40px auto;

  li {
    display: inline-block;
    vertical-align: middle;
  }

  a {
    display: inline-block;
    background: white;
    box-shadow: 0 0 10px 0px #ddd;
    text-align: center;
    width: 135px;
    height: 180px;
    margin: 22px;
    padding: 0px 10px;
    box-sizing: border-box;
    font-size: 13px;
    text-transform: uppercase;

    i {
      display: block;
      height: 130px;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .home {
      background-image: url(../../images/ico/home.png);
    }

    .orders {
      background-image: url(../../images/ico/orders.png);
    }

    .fields {
      background-image: url(../../images/ico/fields.png);
    }

    .crops {
      background-image: url(../../images/ico/crops.png);
    }

    .plantProtections {
      background-image: url(../../images/ico/plantProtections.png);
    }

    .fertilizers {
      background-image: url(../../images/ico/fertilizers.png);
    }

    .labels {
      background-image: url(../../images/ico/labels.png);
    }

    .machines {
      background-image: url(../../images/ico/machines.png);
    }

    .vehicles {
      background-image: url(../../images/ico/vehicles.png);
    }

    .tasks {
      background-image: url(../../images/ico/tasks.png);
    }

    .employers {
      background-image: url(../../images/ico/employers.png);
    }

    .archives {
      background-image: url(../../images/ico/archives.png);
    }

    &:hover {
      background-color: #005a84;
      color: white;

      .home {
        background-image: url(../../images/ico/homeh.png);
      }

      .orders {
        background-image: url(../../images/ico/ordersh.png);
      }

      .fields {
        background-image: url(../../images/ico/fieldsh.png);
      }

      .crops {
        background-image: url(../../images/ico/cropsh.png);
      }

      .plantProtections {
        background-image: url(../../images/ico/plantProtectionsh.png);
      }

      .fertilizers {
        background-image: url(../../images/ico/fertilizersh.png);
      }

      .labels {
        background-image: url(../../images/ico/labelsh.png);
      }

      .machines {
        background-image: url(../../images/ico/machinesh.png);
      }

      .vehicles {
        background-image: url(../../images/ico/vehiclesh.png);
      }

      .tasks {
        background-image: url(../../images/ico/tasksh.png);
      }

      .employers {
        background-image: url(../../images/ico/employersh.png);
      }

      .archives {
        background-image: url(../../images/ico/archivesh.png);
      }
    }
  }

  li.sub {
    margin: 0px;
    padding: 0px;

    > a {
      display: none;
    }

    ul {
      display: inline;
      flex-wrap: wrap;
    }
  }
}

</style>
