import { EventBus } from './bus.js';
const cache = {}
export const Dictionary = {
    async load(list) {
        const res = {}
        for (let i in list) {
            res[list[i]] = Dictionary.partial(list[i])
        }
        for (let i in res) {
            res[i] = await res[i]
        }
        return res
    },
    async partial(path) {
        if (cache[path]) {
            return cache[path]
        } else {
            return EventBus.$http.get(path, {
                params : {
                    own : 'true',
                    archive: 'false',
                    dictionary: 'true'
                }
            }).then((resp) => {
                cache[path] = resp.data.data

                return resp.data.data
            })
        }
    },
    clear(prefix) {

        for (var prop in cache) {
            if (prop === prefix || prop.startsWith(prefix)) {
                delete cache[prop]
            }
        }
    }
}
