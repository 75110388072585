<template>
  <text-highlight :queries="queries||''">{{ formatted }}</text-highlight>
</template>
<script>
import moment from 'moment';
import TextHighlight from 'vue-text-highlight'
export default {
  props: ['row', 'nested', 'value', 'queries'],
  components: {TextHighlight},
  name: 'date-field',
  computed: {
    formatted() {
        if (!this.value) {
            return '-';
        }
        return moment(this.value).format('DD.MM.YYYY')
    }
  },
  methods: {
    
  }
}
</script>