<template>
    <div :class="print ? 'smaller' : ''">
        <div class="search-wrap">
            <input @keyup.enter="filter()" :value="value" @input="searchText = $event.target.value||''" ref="input" type="text" class="search" placeholder="szukaj" />
            <span class="clear" v-if="searchText && searchText.length > 0" @click.prevent="clear()" v-tooltip="'wyczyść'"></span>
            <i @click.prevent.stop="filter()"></i>
        </div>
        <print v-if="print" />
    </div>
</template>
<script> 
    import Print from './Print';
    export default {
        name :'search',
        components: {Print},
        props : {
            value : {
                type: String
            },
            print: {
                type: Boolean,
                default: false
            }
        },
        data : function() {
            return {
                searchText : this.value || ''
            }
        },
        methods: {
            filter() {
                this.$emit('filter', this.searchText);
            },
            clear() {
                this.$refs.input.value = this.searchText = ''
                this.$nextTick(() => {
                    this.filter()
                })
            },
            focus() {
                this.$refs.input.focus()
            },
            
        },
        watch : {
            searchText()  {
                this.$emit('input', this.searchText);
            },
            value() {
                if (this.searchText != this.value) {
                    this.searchText = this.value
                }
            }
        }
    }
</script>
<style lang="scss">
.search-wrap {
    position: relative;
    display: inline-block;
    i {
        background: url(../../../images/ico/search.png) no-repeat center right !important;
        cursor: pointer;
        width: 30px;
        display: inline-block;
        position: absolute;
        top: 0px;
        right: 1px;
        height: 100%;
        
    }
    input {
        padding-right: 30px;
    }
    .clear {
        position: absolute;
        top: 3px;
        cursor: pointer;
        right: 35px;
        width: 20px;
        height: 20px;
        background: url(../../../images/clear.png) no-repeat center center;
        background-size: 80% 80%;
        display: block;
        &:hover {
            opacity: 0.8;
        }
    }
}
.smaller {
    .search-wrap {
        .search {
            width: 250px;
        }
    }
}
</style>