<template>
    <a href="#" @click.prevent="$emit('input', !value)" class="button">
        <template v-if="value">{{hide}}</template>
        <template v-if="!value">{{show}}</template>
     <i class="ico ico-archive"></i></a>
</template>
<script> 
    export default {
        name :'value',
        props : {
            value : {
                type: Boolean
            },
            show: {
                type : String,
                default : 'pokaż niedostępne'
            },
            hide : {
                type: String,
                default: 'ukryj niedostępne'
            }
        },
        data : function() {
            return {
            }
        }
    }
</script>
<style lang="scss">
.yes {
    color: #009e5f;
}
.no {
    color: #cc4242;
}
.grid-table > tbody > tr:hover > td {
    .no, .yes {
        color: white;
    }
}
</style>