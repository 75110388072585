<template>
   <div class="login">
        <vue-form method="POST" :class="formClass" action="#" :state="formstate" @submit.prevent="submit()" ref="form" name="login">
            <h3><i></i> Aktywacja konta</h3>
            <transition name="bounce">
            <span class="error" v-if="error">{{ error }}</span>
            </transition>
            <template v-if="!loading">
                <input type="text" ref="login" v-model="username" placeholder="Login" disabled class="txt name" />
                <validate auto-label class="input-group">
                    <input class="txt pass" ref="password" type="password" name="password" placeholder="Hasło" v-model="password" required password-strength autocomplete="off" />
                    <field-messages auto-label name="password" class="input-error">
                        <div slot="required">Hasło jest wymagane</div>
                        <div slot="password-strength">Wymagane 8 znaków, jedna liczba i jedna litera</div>
                    </field-messages>
                </validate>
                <validate auto-label class="input-group">
                    <input class="txt pass" type="password" placeholder="Powtórz hasło" v-model="password2" required :matches="password" autocomplete="off" name="password2"/>
                    <field-messages auto-label name="password2" class="input-error">
                        <div slot="required">Powtórz hasło</div>
                        <div slot="matches">Hasła nie są takie same </div>
                    </field-messages>
                </validate>
                <div class="btns">
                    <router-link :to="{path : '/login'}">logowanie</router-link> <input type="submit" class="button" value="aktywuj" />
                </div>
            </template>
        </vue-form>
   </div>
</template>
 
<script>
   export default {
    title : 'Logowanie',
    props : {
        id : {type : String},
        token : {type : String}
        
    },
    data : function() {
        return {
            username : '',
            formstate : {},
            password : '',
            password2: '',
            error : '',
            formClass : '',
            loading: true
        };
    },
    mounted : function() {
        this.$http.get('auth/activate/' + this.id + '/' + this.token).then(resp => {
            this.loading = false;
            this.username = resp.data.username
            //this.$refs.password.focus();
        }).catch(() => {
            this.error = "Link nieaktywny"
        })
    },
    methods : {
        submit : function() {
            if(this.formstate.$invalid) {
                    return;
            }
            const username = this.username;
            const password = this.password;
            this.formClass = 'bounceOut animated';
            this.$http.put('auth/activate/' + this.id + '/' + this.token, {
                password : this.password
            }).then(reponse => {
                this.$router.push({path : '/login'});
            }).catch(error => {
                this.formClass = 'bounceIn animated';
                this.error = error.response.data.error
            })
        }
    }
   }
</script>
 
<style scoped>
 .login {
    padding-top: 100px;
 }
 form {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(198,217,229,1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(198,217,229,1);
    box-shadow: 0px 0px 8px 0px rgba(198,217,229,1);
    background: white;
    border: 1px solid #c6d9e5;
    padding: 2px;
    max-width: 495px;
    margin: 0 auto;
    overflow: hidden;
 }
 h3 {
    font-size: 20px;
    color: #005691;
    text-transform: uppercase;
    line-height: 60px;
    border-bottom: 1px solid #c9dbe8;
    text-align: center;
    margin-bottom: 30px;
 }
 h3 i  {
    display: inline-block;
    width: 23px;
    height: 30px;
    vertical-align: middle;
    margin-right: 10px;
 }
 form .txt {
    width: 340px;
    box-sizing: border-box;
    margin: 18px auto;
    display: block;
    border: 1px solid #c6d9e5;
    font-size: 16px;
    color: #7a7a7a;
    padding: 10px;
 }
 .btns {
    width: 340px;
    margin: 0 auto;
    position: relative;
    height: 40px;
    line-height: 40px;
    padding-top: 30px;
    padding-bottom: 50px;
 }
 .btns .button {
    position: absolute;
    top: 30px;
    right: 0px;
 }
 p {
    text-align: center;
    font-weight: 300;
    color: #005691;
    line-height: 1.5em;
    padding: 20px 0px;
} 
p.info {
    padding-top: 40px;
}
.error, .input-error {
    display: block;
    text-align: center;
    color: red;
    font-weigh: bold;
}
</style>