<template>
   <div>
       <p>Error 404</p>
   </div>
</template>
 
<script>
   export default {
       title : 'Strony nie znaleziono'
   }
</script>
 
<style scoped>
 
</style>